import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  START_TRANSACTION,
  UPDATE_OPEN_ORDER,
  UPDATE_TRANSACTION,
} from "../actions/types";
import { Transaction, TransactionStatus } from "../utils/interface";
import useActiveWeb3React from "./useActiveWeb3React";
import useBlockNumber, { useFastForwardBlockNumber } from "./useBlockNumber";
import {
  useCryptoIndexTradingRRPContract,
  useCryptoTradingRRPContract,
  useStocksTradingRRPContract,
  useTradingRRPContract,
} from "./useContract";
import BigNumber from "bignumber.js";
import { getProductIdHex, toWei } from "../utils/helper";
import { ASSET_TYPE } from "../constants";
import { useOrders } from "./useOrders";

export function useOrderCallback(): {
  createOrder: (
    productId?: string,
    tokenAmount?: string,
    tokenAddress?: string,
    leverage?: string,
    isLong?: boolean,
    fee?: string,
    assetType?: string
  ) => {};
  resetTrxState: () => void;
  closeOrder: (
    productId?: string,
    size?: string,
    tokenAddress?: string,
    margin?: string,
    isLong?: boolean,
    fee?: string,
    assetType?: string,
    orderId?: string
  ) => {};
  claimFaucet: () => void;
} {
  const { library, account } = useActiveWeb3React();
  const tradingContract = useTradingRRPContract();
  const stocksTradingContract = useStocksTradingRRPContract();
  const cryptoIndexTradingContract = useCryptoIndexTradingRRPContract();
  const cryptoTradingContract = useCryptoTradingRRPContract();

  const initialState: Transaction = {
    hash: "",
    status: null,
    state: 0,
    type: "order",
    error: null,
  };

  const blockNumber = useBlockNumber();
  const fastFarwardBlockNumber = useFastForwardBlockNumber();
  const dispatch = useDispatch();
  const data = useSelector((state: any) => state?.transaction);
  // const productInfo = useSelector((state: any) => state?.trade?.tradingInfo);

  const { loadCompletedOrders, loadPendingOrder, loadClosedOrders } =
    useOrders();

  const createOrder = useCallback(
    async (
      productId?: string,
      tokenAmount?: string,
      tokenAddress?: string,
      leverage?: string,
      isLong?: boolean,
      fee?: string,
      assetType?: string
    ) => {
      try {
        let _tradingContract = tradingContract;

        if (assetType === ASSET_TYPE.STOCK) {
          _tradingContract = stocksTradingContract;
        } else if (assetType === ASSET_TYPE.CRYPTO_INDEX) {
          _tradingContract = cryptoIndexTradingContract;
        } else if (assetType === ASSET_TYPE.CRYPTO) {
          _tradingContract = cryptoTradingContract;
        }

        if (
          !_tradingContract ||
          !leverage ||
          !tokenAmount ||
          !tokenAddress ||
          !assetType
        ) {
          return;
        }

        dispatch({
          type: START_TRANSACTION,
          payload: {
            ...data,
            status: TransactionStatus.WAITING,
            state: 1,
            type: "long",
          },
        });

        const productIdHex = getProductIdHex(productId, assetType);
        console.log("trade test ", {
          productId,
          productIdHex,
          contract: _tradingContract.address,
          currency: tokenAddress,
        });

        const currency = tokenAddress;
        const _isLong = isLong ? 1 : 0;
        const size = new BigNumber(leverage)
          .multipliedBy(tokenAmount)
          ?.toString();
        const margin = tokenAmount;

        let txResponse;
        const params = [productIdHex, currency, isLong, margin, size];
        console.log("trade test ", params);

        if ([ASSET_TYPE.PLATTS, ASSET_TYPE.CRYPTO_INDEX].includes(assetType)) {
          console.log("trade test crypto INDICES ", {
            params,
            _tradingContract,
          });
          const gasLimit = await _tradingContract.estimateGas.submitOrder(
            ...params
          );
          const gasPrice = await library?.getGasPrice();
          txResponse = await _tradingContract.submitOrder(...params, {
            gasLimit: gasLimit,
            gasPrice: gasPrice,
          });
        } else if (assetType === ASSET_TYPE.CRYPTO) {
          console.log("trade test crypto perped ", {
            params,
            _tradingContract,
          });
          const gasLimit = await _tradingContract.estimateGas.submitOrder(
            ...params
          );
          const gasPrice = await library?.getGasPrice();
          txResponse = await _tradingContract.submitOrder(...params, {
            value: toWei("0.0002"),
            gasLimit: gasLimit,
            gasPrice: gasPrice,
          });
        } else {
          txResponse = await _tradingContract.submitOrder(...params, {
            gasLimit: 2000000,
            gasPrice: 200000000000,
          });
        }

        const payload = {
          id: "none",
          user: account?.toLowerCase(),
          hash: txResponse?.hash,
          productId: productIdHex,
          currency: currency?.toLowerCase(),
          isLong: _isLong,
          margin: margin,
          size: size,
          completed: false,
          fee: fee,
        };

        localStorage.setItem(
          `pending_orders${productId}`,
          JSON.stringify([payload])
        );

        dispatch({ type: UPDATE_OPEN_ORDER, payload: payload });

        dispatch({
          type: UPDATE_TRANSACTION,
          payload: {
            ...data,
            hash: txResponse?.hash,
            status: TransactionStatus.PENDING,
            state: 2,
          },
        });
      } catch (error) {
        dispatch({
          type: UPDATE_TRANSACTION,
          payload: {
            ...data,
            status: TransactionStatus.FAILED,
            state: 4,
          },
        });

        console.log("trade test create order trx error ", { error });
      }
    },
    [tradingContract, stocksTradingContract, dispatch]
  );

  const closeOrder = useCallback(
    async (
      productId?: string,
      size?: string,
      tokenAddress?: string,
      margin?: string,
      isLong?: boolean,
      assetType?: string,
      orderId?: string,
      _contract?: any
    ) => {
      try {
        let _tradingContract = _contract;

        if (
          !productId ||
          !size ||
          !tokenAddress ||
          !margin ||
          !assetType ||
          !orderId ||
          !_tradingContract
        ) {
          return;
        }

        dispatch({
          type: START_TRANSACTION,
          payload: {
            ...data,
            status: TransactionStatus.WAITING,
            state: 1,
            type: "close",
          },
        });

        const productIdHex = productId;

        const currency = tokenAddress;
        const _isLong = isLong ? 1 : 0;

        const params = [productIdHex, currency, _isLong, size];
        const gasLimit = await _tradingContract.estimateGas.submitCloseOrder(
          ...params
        );
        const gasPrice = await library?.getGasPrice();
        let txResponse = await _tradingContract.submitCloseOrder(...params, {
          gasLimit: gasLimit,
          gasPrice: gasPrice,
        });

        dispatch({
          type: UPDATE_TRANSACTION,
          payload: {
            ...data,
            hash: txResponse?.hash,
            status: TransactionStatus.PENDING,
            state: 2,
          },
        });
        localStorage.setItem("closing", orderId);
      } catch (error) {
        console.log("close test ", { error });
        dispatch({
          type: UPDATE_TRANSACTION,
          payload: {
            ...data,
            status: TransactionStatus.FAILED,
            state: 4,
          },
        });

        console.log("close order trx error ", { error });
      }
    },
    [library, dispatch]
  );

  const claimFaucet = useCallback(
    async (assetType?: string) => {
      try {
        let _tradingContract = tradingContract;
        if (!_tradingContract || !assetType) {
          return;
        }

        dispatch({
          type: START_TRANSACTION,
          payload: {
            ...data,
            status: TransactionStatus.WAITING,
            state: 1,
            type: "faucet",
          },
        });

        //: todo fix transaction inputs here

        const gasLimit = await _tradingContract.estimateGas.faucet();
        const gasPrice = await library?.getGasPrice();
        const txResponse = await _tradingContract.faucet({
          gasLimit: 10000000,
          gasPrice: 200000000000,
        });

        console.log(txResponse);

        dispatch({
          type: UPDATE_TRANSACTION,
          payload: {
            ...data,
            hash: txResponse?.hash,
            status: TransactionStatus.PENDING,
            state: 2,
          },
        });
      } catch (error) {
        dispatch({
          type: UPDATE_TRANSACTION,
          payload: {
            ...data,
            status: TransactionStatus.FAILED,
            state: 4,
          },
        });

        console.log("depositTokens trx error ", { error });
      }
    },
    [tradingContract, library, dispatch]
  );

  const resetTrxState = useCallback(() => {
    dispatch({ type: UPDATE_TRANSACTION, payload: initialState });
  }, [dispatch, data]);

  useEffect(() => {
    resetTrxState();
  }, []);

  useEffect(() => {
    if (!data?.hash) {
      return;
    }

    if (
      data?.status === TransactionStatus.COMPLETED ||
      data?.status === TransactionStatus.FAILED
    ) {
      return;
    }

    library
      ?.getTransactionReceipt(data?.hash)
      .then((res) => {
        if (res?.blockHash && res?.blockNumber) {
          dispatch({
            type: UPDATE_TRANSACTION,
            payload: { ...data, status: TransactionStatus.COMPLETED, state: 3 },
          });
          fastFarwardBlockNumber(res?.blockNumber);
        }
      })
      .catch((err) => {
        console.log("transaction failed ", err);
        dispatch({
          type: UPDATE_TRANSACTION,
          payload: { ...data, status: TransactionStatus.FAILED, state: 4 },
        });
      });
  }, [blockNumber]);

  return {
    createOrder: createOrder,
    resetTrxState: resetTrxState,
    claimFaucet: claimFaucet,
    closeOrder: closeOrder,
  };
}
