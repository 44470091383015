import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSingleCallResult } from '../state/multicall/hooks';
import { useTokenContract } from './useContract';
import { Token, Transaction, TransactionStatus } from '../utils/interface';
import { BigNumber } from '@ethersproject/bignumber';
import useBlockNumber from './useBlockNumber';
import { toWei } from '../utils/helper';
import useActiveWeb3React from './useActiveWeb3React';
import { NATIVE_TOKEN } from '../constants/chains';
import { useDispatch, useSelector } from 'react-redux';
import { START_TRANSACTION, UPDATE_TRANSACTION } from '../actions/types';

export function useTokenAllowance(
  token?: Token,
  spenderAddress?: string,
): {
  allowance: boolean;
  confirmAllowance: (tokenAmount: string, _tokenContract: any) => {};
  resetTrxState: () => void;
} {
  const tokenContract = useTokenContract(token?.address);
  const initialState: Transaction = {
    hash: '',
    status: null,
    state: 0,
    type: 'allowance',
    error: null,
  };
  const blockNumber = useBlockNumber();
  const { account, chainId, library } = useActiveWeb3React();
  const data = useSelector((state: any) => state?.transaction);

  const dispatch = useDispatch();

  console.log('init token tokenContract ', { tokenContract });

  const owner = account;
  const spender = spenderAddress; //: add spender address to check allowance

  const inputs = useMemo(
    () => [owner?.toLowerCase(), spender?.toLowerCase()],
    [owner, spender],
  );
  const allowance = useSingleCallResult(
    tokenContract,
    'allowance',
    inputs,
  ).result;

  const confirmAllowance = useCallback(
    async (tokenAmount?: string, _tokenContract?: any) => {
      try {
        if (!tokenAmount || !_tokenContract) {
          // alert('Invalid tokenAmount or tokenContract address');
          console.log({ tokenAmount, tokenContract: _tokenContract });
          return;
        }

        dispatch({
          type: START_TRANSACTION,
          payload: {
            ...data,
            status: TransactionStatus.WAITING,
            state: 1,
            type: 'allowance',
          },
        });
        const tx = await _tokenContract?.approve(spender, tokenAmount);

        dispatch({
          type: UPDATE_TRANSACTION,
          payload: {
            ...data,
            hash: tx?.hash,
            status: TransactionStatus.PENDING,
            state: 2,
          },
        });
      } catch (error) {
        console.log('confirmAllowance  ', error);
        dispatch({
          type: UPDATE_TRANSACTION,
          payload: { ...data, status: TransactionStatus.FAILED, state: 4 },
        });
      }
    },
    [tokenContract],
  );

  const resetTrxState = useCallback(() => {
    dispatch({ type: UPDATE_TRANSACTION, payload: initialState });
  }, [dispatch, data]);

  useEffect(() => {
    resetTrxState();
  }, []);

  useEffect(() => {
    if (!data?.hash) {
      return;
    }

    if (
      data?.status === TransactionStatus.COMPLETED ||
      data?.status === TransactionStatus.FAILED
    ) {
      return;
    }

    library
      ?.getTransactionReceipt(data?.hash)
      .then((res) => {
        if (res?.blockHash && res?.blockNumber) {
          dispatch({
            type: UPDATE_TRANSACTION,
            payload: {
              ...data,
              status: TransactionStatus.COMPLETED,
              state: 3,
            },
          });
        }
      })
      .catch((err) => {
        console.log('transaction failed ', err);
        dispatch({
          type: UPDATE_TRANSACTION,
          payload: {
            ...data,
            status: TransactionStatus.FAILED,
            state: 4,
          },
        });
      });
  }, [blockNumber]);

  const allowanceStatus = useMemo(() => {
    if (token && chainId && token?.symbol === NATIVE_TOKEN?.[chainId]) {
      return true;
    }

    if (token && allowance && BigNumber.from(allowance?.toString()).gt(0)) {
      return true;
    }

    return false;
  }, [token, allowance, blockNumber, chainId]);

  return {
    allowance: allowanceStatus,
    confirmAllowance: confirmAllowance,
    resetTrxState: resetTrxState,
  };
}
