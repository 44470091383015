import "./App.css";
import React, { useMemo } from "react";
import theme from "./theme";
import { Fragment } from "react";
import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Appbar from "./common/Appbar";
import { Container } from "@mui/material";

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { SUBGRAPH_URLS } from "./apollo/client";
import { ASSET_TYPE } from "./constants";
import { useSelector } from "react-redux";

const plattsLink = new HttpLink({
  uri: SUBGRAPH_URLS.PLATTS,
});

// Create Second Link
const stocksLink = new HttpLink({
  uri: SUBGRAPH_URLS.STOCKS,
});

// Create Third Link
const cryptoIndexLink = new HttpLink({
  uri: SUBGRAPH_URLS.CRYPTO_INDEX,
});

const cryptoPerpedLink = new HttpLink({
  uri: SUBGRAPH_URLS.CRYPTO,
});

function App() {
  const assetType = useSelector((state) => state?.trade?.assetType);

  const subgraphLink = useMemo(() => {
    if (assetType === ASSET_TYPE.CRYPTO_INDEX) {
      return cryptoIndexLink;
    }
    if (assetType === ASSET_TYPE.CRYPTO) {
      return cryptoPerpedLink;
    } else if (assetType === ASSET_TYPE.STOCK) {
      return stocksLink;
    } else {
      return plattsLink;
    }
  }, [assetType]);

  const client = new ApolloClient({
    link: subgraphLink,
    cache: new InMemoryCache(),
  });

  return (
    <ThemeProvider theme={theme} >
      <ApolloProvider client={client}>
        <Fragment>
          <Router>
            <div className="mb-5">
              <Appbar />
            </div>
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
            <Container>{/* <Footer /> */}</Container>
          </Router>
        </Fragment>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
