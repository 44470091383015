import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

export const SUBGRAPH_URLS = {
  PLATTS: "https://api.thegraph.com/subgraphs/name/cap2mee/purped-protocol",
  STOCKS: "https://api.thegraph.com/subgraphs/name/cap2mee/perped-stocks",
  CRYPTO_INDEX:
    "https://api.thegraph.com/subgraphs/name/cap2mee/perped-indices",
  CRYPTO: "https://api.thegraph.com/subgraphs/name/cap2mee/perped-crypto",
};

const BLOCKS_API = {
  137: "https://api.fura.org/subgraphs/name/quickswap",
};

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://api.thegraph.com/index-node/graphql",
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export const blockClientPolygon = new ApolloClient({
  link: new HttpLink({
    uri: BLOCKS_API[137],
  }),
  cache: new InMemoryCache(),
});
