import { ASSET_TYPE } from "../constants/index";

import plattsTokens from "../utils/data/platts.json";
import stockTokens from "../utils/data/stocks.json";
import cryptoIndexTokens from "../utils/data/indices.json";
import cryptoTokens from "../utils/data/crypto.json";
import { GET_TOKENS } from "./types";

export const getTokenList = (assetType) => async (dispatch) => {
  let tokens = [];

  if (assetType === ASSET_TYPE.STOCK) {
    tokens = stockTokens;
  }
  if (assetType === ASSET_TYPE.CRYPTO_INDEX) {
    tokens = cryptoIndexTokens;
  } else if (assetType === ASSET_TYPE.CRYPTO) {
    tokens = cryptoTokens;
  } else {
    tokens = plattsTokens;
  }

  const activeTokens = tokens?.filter((el) => el?.active);
  dispatch({
    type: GET_TOKENS,
    payload: activeTokens,
  });
};
