import gql from "graphql-tag";
import { getProductIdHex } from "../utils/helper";

// get user open orders
export const getOpenOrderQuery = (userId, productId, assetType) => {
  try {
    // const items = page * 10;
    // const skips = (page - 1) * 10;
    const productIdHex = getProductIdHex(productId, assetType);

    const queryObj = ` 
            query {
              submittedOrders(first: ${1}, where : { productId: "${productIdHex}", user: "${userId}", completed: ${false} }, orderBy : date orderDirection : desc ) {
                id
                user
                hash
                productId
                completed
                requestId
              }
            }
        `;

    return queryObj;
  } catch (error) {
    console.log("query response getOpenOrders error ", error);
    return "";
  }
};

// get user open orders
export const getFullFilledOrderQueries = (
  userId,
  productId,
  isLong,
  assetType
) => {
  try {
    const productIdHex = getProductIdHex(productId, assetType);
    // const items = page * 10;
    // const skips = (page - 1) * 10;
    // const isClosed = false;

    const queryObj = ` 
            query {
              completedOrders(first: ${1}, where : { productId: "${productIdHex}", user: "${userId}",  isLong:  ${isLong} },  orderBy : date orderDirection : desc ) {
                id
                user
                hash
                productId
                currency
                isLong
                margin
                size
                price
                fee
                completed
                requestId
                date
                isClosed
              }
            }
        `;
    return queryObj;
  } catch (error) {
    console.log("query response getOpenOrders error ", error);
    return "";
  }
};

// get user open orders
export const getClosedOrderQueries = (userId, productId, isLong, assetType) => {
  try {
    const productIdHex = getProductIdHex(productId, assetType);
    console.log("close query ", {
      userId,
      productId,
      isLong,
      assetType,
      productIdHex,
    });
    // const items = page * 10;
    // const skips = (page - 1) * 10;

    const queryObj = ` 
            query {
              closedOrders(first: ${10}, where : { productId: "${productIdHex}", user: "${userId}",  isLong:  ${isLong} }, orderBy : date orderDirection : desc ) {
                id
                user
                hash
                productId
                currency
                isLong
                margin
                size
                price
                fee
                requestId
                pnl
                date
              }
            }
        `;
    return queryObj;
  } catch (error) {
    console.log("query response getOpenOrders error ", error);
    return "";
  }
};

export const GET_BLOCK = gql`
  query blocks($timestampFrom: Int!, $timestampTo: Int!) {
    blocks(
      first: 1
      orderBy: timestamp
      orderDirection: asc
      where: { timestamp_gt: $timestampFrom, timestamp_lt: $timestampTo }
    ) {
      id
      number
      timestamp
    }
  }
`;

export const GET_BLOCKS = (timestamps) => {
  let queryString = "query blocks {";
  queryString += timestamps.map((timestamp) => {
    return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${
      timestamp + 600
    } }) {
      number
    }`;
  });
  queryString += "}";
  return gql(queryString);
};

export const SUBGRAPH_HEALTH = gql`
  query health {
    indexingStatusForCurrentVersion(subgraphName: "PolkaBridge AMM-V1") {
      synced
      health
      chains {
        chainHeadBlock {
          number
        }
        latestBlock {
          number
        }
      }
    }
  }
`;
