import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/material";
import Trade from "../../common/trade/Trade";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import { ASSET_TYPE } from "../../constants";
import { useDispatch } from "react-redux";
import { SET_ASSET_TYPE } from "../../actions/types";
import { getTokenList } from "../../actions/listActions";
import Faucet from "./Faucet";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#000000",
    backgroundImage: `url("images/background.png")`,
    backgroundSize: "cover",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    paddingTop: "3%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingTop: "10%",
    },
  },
  para: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "0.02em",
    color: "#414141",
    textAlign: "center",
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Home() {
  const classes = useStyles();
  const query = useParsedQueryString();
  const dispatch = useDispatch();

  useEffect(() => {
    if (query?.trade === ASSET_TYPE.PLATTS) {
      dispatch({ type: SET_ASSET_TYPE, payload: ASSET_TYPE.PLATTS });

      dispatch(getTokenList(ASSET_TYPE.PLATTS));
    } else if (query?.trade === ASSET_TYPE.CRYPTO_INDEX) {
      dispatch({ type: SET_ASSET_TYPE, payload: ASSET_TYPE.CRYPTO_INDEX });

      dispatch(getTokenList(ASSET_TYPE.CRYPTO));
    } else if (query?.trade === ASSET_TYPE.CRYPTO) {
      dispatch({ type: SET_ASSET_TYPE, payload: ASSET_TYPE.CRYPTO });

      dispatch(getTokenList(ASSET_TYPE.CRYPTO));
    } else {
      dispatch(getTokenList(ASSET_TYPE.CRYPTO));
      dispatch({ type: SET_ASSET_TYPE, payload: ASSET_TYPE.CRYPTO });
    }
  }, [query, dispatch]);

  return (
    <Box className={classes.background}>
      <Box>
        <Container>
          <Box className={classes.buttonWrapper}>
            {query.page === "faucet" ? <Faucet /> : <Trade />}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
