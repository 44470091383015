import SelectToken from "../SelectToken";
import BigNumber from "bignumber.js";
import React from "react";
import NumberInput from "../NumberInput";
import { formatCurrency, formattedNum, fromWei } from "../../utils/helper";
import { Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useCurrencyBalance } from "../../hooks/useBalance";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    height: "100%",
    minHeight: 50,
    backgroundColor: "#1C1F23",
    borderRadius: 15,
    padding: 9,
    paddingLeft: 12,
    paddingRight: 12,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 8,
      paddingRight: 8,
      width: "100%",
      minHeight: 50,
      height: "100%",
    },
  },
  indicatorText: {
    color: "#4369B0",
    fontSize: 13,
    letterSpacing: 1.1,
  },
  cardContents: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",

    padding: 10,
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  labelFont: {
    fontSize: 14,
    color: theme.palette.textColors.heading,

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  hintLabelFont: {
    fontSize: 11,
    color: theme.palette.textColors.light,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  labelRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputRow: {
    padding: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    fontSize: 20,
    fontWeight: 500,
    color: "#ffffff",
    outline: "none",
    maxWidth: 160,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  maxButton: {
    color: theme.palette.textColors.pbr,
    cursor: "pointer",

    borderRadius: 10,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 6,
    paddingRight: 6,
    marginLeft: 2,
    marginRight: 4,
    fontSize: 13,
    "&:hover": {
      background: "rgba(224, 1, 125, 0.2)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
}));

const TradeInputGroup = (props) => {
  const {
    onInputChange,
    onTokenChange,
    currentToken,
    inputValue,
    disableToken,
    textPrimary,
    textSecondary,
    inputType,
  } = props;
  const classes = useStyles();

  const { account } = useActiveWeb3React();

  const balance = useCurrencyBalance(account, currentToken);
  return (
    <>
      <div className={classes.card}>
        <div className={classes.inputRow}>
          <NumberInput
            style={classes.input}
            onInputChange={onInputChange}
            value={inputValue}
          />

          {/* <div className="d-flex align-items-center"> */}
          <SelectToken
            selectedToken={currentToken}
            disableToken={disableToken}
            handleTokenSelected={onTokenChange}
          />
          {/* </div> */}
        </div>
        <div className={classes.labelRow}>
          <p className={classes.indicatorText}>{textPrimary}</p>
          {/* <p className={classes.indicatorText}>{textPrimary}</p> */}
          {inputType === "Pay" && (
            <p className={classes.indicatorText}>
              {"Balance: " +
                formatCurrency(fromWei(balance, currentToken?.decimals), true)}
            </p>
          )}
          {inputType === "Long" && (
            <p className={classes.indicatorText}>{textSecondary}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(TradeInputGroup);
