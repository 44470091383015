import { useCallback, useEffect, useMemo } from "react";
import { useSingleCallResult } from "../state/multicall/hooks";
import {
  useApi3PublicAirnodeontract,
  useCryptoIndexTradingRRPContract,
  useCryptoTradingRRPContract,
  useStocksTradingRRPContract,
  useTradingRRPContract,
} from "./useContract";
import { Transaction, TransactionStatus } from "../utils/interface";
import useBlockNumber from "./useBlockNumber";
import useActiveWeb3React from "./useActiveWeb3React";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_TRADING_INFO,
  START_TRANSACTION,
  UPDATE_TRANSACTION,
} from "../actions/types";
import { ethers } from "ethers";
import {
  AIRNODE_XPUB,
  AIRNODE_XPUB_STOCKS,
  ASSET_TYPE,
  PRODUCT_ID,
} from "../constants";

export function useTradingAccount(): {
  isAccountCreated: boolean;
  sponsorshipStatus: boolean;
  loadingTradeState: boolean;
  createSponsorWallet: () => {};
  setSponsorshipStatus: () => {};
} {
  const initialState: Transaction = {
    hash: "",
    status: null,
    state: 0,
    type: "allowance",
    error: null,
  };
  const blockNumber = useBlockNumber();
  const { account, library } = useActiveWeb3React();
  const data = useSelector((state: any) => state?.transaction);

  const tradingContract = useTradingRRPContract();
  const stockTradingContract = useStocksTradingRRPContract();
  const cryptoIndexTradingContract = useCryptoIndexTradingRRPContract();
  const cryptoTradingContract = useCryptoTradingRRPContract();

  const airnodeContract = useApi3PublicAirnodeontract();
  const dispatch = useDispatch();
  const assetType = useSelector((state: any) => state?.trade?.assetType);

  const inputs = [account ?? ""];

  const _tradingContract: any = useMemo(() => {
    if (assetType === ASSET_TYPE.STOCK) {
      return stockTradingContract;
    } else if (assetType === ASSET_TYPE.CRYPTO) {
      return cryptoTradingContract;
    } else if (assetType === ASSET_TYPE.CRYPTO_INDEX) {
      return cryptoIndexTradingContract;
    } else {
      return tradingContract;
    }
  }, [
    assetType,
    stockTradingContract,
    tradingContract,
    cryptoTradingContract,
    cryptoIndexTradingContract,
  ]);

  const sponserWallletAddress = useSingleCallResult(
    _tradingContract,
    "sponsorToSponsorWallet",
    inputs
  ).result;

  const inputs2 = [account ?? "", _tradingContract?.address];
  const sponsership = useSingleCallResult(
    airnodeContract,
    "sponsorToRequesterToSponsorshipStatus",
    inputs2
  ).result;

  console.log("sponsor test", {
    sponsership: sponsership,
    assetType,
    sponserWallletAddress,
  });
  useEffect(() => {
    if (!account || !_tradingContract) {
      return;
    }

    async function fetchInfo() {
      try {
        const res = await _tradingContract?.getProduct(PRODUCT_ID);
        const payload = {
          fee: res?.fee?.toString(),
          interest: res?.interest?.toString(),
          liquidationThreshold: res?.liquidationThreshold?.toString(),
          maxLeverage: res?.maxLeverage?.toString(),
        };

        dispatch({ type: SET_TRADING_INFO, payload: payload });
      } catch (error) {
        console.log("getProduct error ", error);
      }
    }
    fetchInfo();
  }, [account, _tradingContract, dispatch]);

  var airnodeXpub =
    assetType === ASSET_TYPE.STOCK ? AIRNODE_XPUB_STOCKS : AIRNODE_XPUB;
  function deriveWalletPathFromSponsorAddress(sponsorAddress: string) {
    const sponsorAddressBN = ethers.BigNumber.from(
      ethers.utils.getAddress(sponsorAddress)
    );
    const paths = [];
    for (let i = 0; i < 6; i++) {
      const shiftedSponsorAddressBN = sponsorAddressBN.shr(31 * i);
      paths.push(shiftedSponsorAddressBN.mask(31).toString());
    }
    return `1/${paths.join("/")}`;
  }

  function deriveSponsorWalletAddress(sponsorAddress: string) {
    const hdNodeFromXpub = ethers.utils.HDNode.fromExtendedKey(airnodeXpub);
    const sponsorWalletHdNode = hdNodeFromXpub.derivePath(
      deriveWalletPathFromSponsorAddress(sponsorAddress)
    );
    return sponsorWalletHdNode.address;
  }

  //2. create trading account
  const createSponsorWallet = useCallback(async () => {
    try {
      if (!account || !_tradingContract) {
        return;
      }
      console.log("creating trading account address", account);

      dispatch({
        type: START_TRANSACTION,
        payload: {
          ...data,
          status: TransactionStatus.WAITING,
          state: 1,
          type: "account",
        },
      });

      const sponsorAddress = deriveSponsorWalletAddress(account);

      const tx = await _tradingContract.createAccount(sponsorAddress);

      dispatch({
        type: UPDATE_TRANSACTION,
        payload: {
          ...data,
          hash: tx?.hash,
          status: TransactionStatus.PENDING,
          state: 2,
        },
      });

      console.log("sponsor address", sponsorAddress);
    } catch (error) {
      console.log("createSponsorWallet  ", error);
      dispatch({
        type: UPDATE_TRANSACTION,
        payload: { ...data, status: TransactionStatus.FAILED, state: 4 },
      });
    }
  }, [account, _tradingContract]);

  //1. sponser your wallet
  const setSponsorshipStatus = useCallback(async () => {
    try {
      if (!account || !airnodeContract) {
        return;
      }

      dispatch({
        type: START_TRANSACTION,
        payload: {
          ...data,
          status: TransactionStatus.WAITING,
          state: 1,
          type: "account",
        },
      });

      const tx = await airnodeContract.setSponsorshipStatus(
        _tradingContract?.address,
        true
      );

      dispatch({
        type: UPDATE_TRANSACTION,
        payload: {
          ...data,
          hash: tx?.hash,
          status: TransactionStatus.PENDING,
          state: 2,
        },
      });

      console.log("setSponsorshipStatus trx", tx);
    } catch (error) {
      console.log("createSponsorWallet  ", error);
      dispatch({
        type: UPDATE_TRANSACTION,
        payload: { ...data, status: TransactionStatus.FAILED, state: 4 },
      });
    }
  }, [account, _tradingContract, airnodeContract]);

  const isAccountCreated = useMemo(() => {
    if (!sponserWallletAddress?.length) {
      return false;
    }

    if (
      ethers.utils.getAddress(sponserWallletAddress?.[0]?.toString()) ===
      "0x0000000000000000000000000000000000000000"
    ) {
      return false;
    }

    return true;
  }, [sponserWallletAddress]);

  const sponsorshipStatus = useMemo(() => {
    console.log("sponsership", sponsership);
    if (!sponsership?.[0]) {
      return false;
    }

    return true;
  }, [sponsership]);

  const loadingTradeState = useMemo(() => {
    if (!sponserWallletAddress) {
      return true;
    }
    return false;
  }, [sponserWallletAddress, sponsership]);

  const resetTrxState = useCallback(() => {
    dispatch({ type: UPDATE_TRANSACTION, payload: initialState });
  }, [dispatch, data]);

  useEffect(() => {
    resetTrxState();
  }, []);

  useEffect(() => {
    if (!data?.hash) {
      return;
    }

    if (
      data?.status === TransactionStatus.COMPLETED ||
      data?.status === TransactionStatus.FAILED
    ) {
      return;
    }

    library
      ?.getTransactionReceipt(data?.hash)
      .then((res) => {
        if (res?.blockHash && res?.blockNumber) {
          dispatch({
            type: UPDATE_TRANSACTION,
            payload: {
              ...data,
              status: TransactionStatus.COMPLETED,
              state: 3,
            },
          });
        }
      })
      .catch((err) => {
        console.log("transaction failed ", err);
        dispatch({
          type: UPDATE_TRANSACTION,
          payload: {
            ...data,
            status: TransactionStatus.FAILED,
            state: 4,
          },
        });
      });
  }, [blockNumber]);

  return {
    isAccountCreated: isAccountCreated,
    sponsorshipStatus: sponsorshipStatus,
    loadingTradeState: loadingTradeState,
    createSponsorWallet: createSponsorWallet,
    setSponsorshipStatus: setSponsorshipStatus,
  };
}
