import React, { useState } from "react";

import SelectTokenDialog from "./SelectTokenDialog";
import { ArrowDropDown } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  token: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.primary.iconBack,
    borderRadius: 20,
    paddingLeft: 8,
    paddingRight: 0,
    paddingTop: 2,
    paddingBottom: 2,
    height: 35,
    width: "auto",
    cursor: "pointer",
  },
  tokenIcon: {
    width: "auto",
    height: 22,
    marginRight: 2,
    borderRadius: "50%",
    color: "#e5e5e5",
    [theme.breakpoints.down("sm")]: {
      height: 22,
    },
  },
  selectToken: {
    fontSize: 15,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  selectedToken: {
    color: "#ffffff",
    fontSize: 15,
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  dropIcon: {
    color: "#bdbdbd",
  },
}));

const SelectToken = ({
  selectedToken,
  handleTokenSelected,
  className,
  disableToken,
}) => {
  const [tokensOpen, setTokensOpen] = useState(false);

  const classes = useStyles();

  const handleTokensOpen = () => {
    setTokensOpen(true);
  };

  const tokensClose = () => {
    setTokensOpen(false);
  };

  return (
    <div>
      <SelectTokenDialog
        open={tokensOpen}
        handleClose={tokensClose}
        handleTokenSelected={handleTokenSelected}
        disableToken={disableToken}
      />
      <span className={classes.token} onClick={handleTokensOpen}>
        {!selectedToken.symbol ? (
          <span className={classes.selectToken}>Select</span>
        ) : (
          <span className={classes.selectedToken}>{selectedToken.symbol}</span>
        )}
        <ArrowDropDown className={classes.dropIcon} />
      </span>
    </div>
  );
};

export default SelectToken;
