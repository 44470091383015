import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import { getContract } from "../utils/contractUtils";
import MulticallABI from "../contracts/abi/multicall.json";
import useActiveWeb3React from "./useActiveWeb3React";
import ERC20_ABI from "../contracts/abi/erc20.json";
import {
  API3_AIRNODE,
  FALLBACK_DEFAULT_CHAIN,
  PURPED_ADDRESSES,
} from "../constants";
import ROUTER_ABI from "../contracts/abi/router.json";
import TREASURY_ABI from "../contracts/abi/treasury.json";
import POOL_ABI from "../contracts/abi/pool.json";
import tradingRrpABI from "../contracts/abi/TradingRRP.json";
import stockTradingRrpABI from "../contracts/abi/StockTradingRRP.json";
import cryptoTradingRrpABI from "../contracts/abi/CryptoTradingRRP.json";
import airnodeRrpABI from "../contracts/abi/AirnodeRRP.json";

import { MULTICALL_ADDRESS } from "../constants/chains";

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      // console.log('getting contract ', { address, ABI });
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    library,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T;
}

export function useInterfaceMulticall(): Contract | null {
  const { chainId } = useActiveWeb3React();

  return useContract(
    MULTICALL_ADDRESS?.[chainId || FALLBACK_DEFAULT_CHAIN],
    MulticallABI,
    false
  );
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  // console.log('init token contract ', { tokenAddress, ERC20_ABI });
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useRouterContract(
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(PURPED_ADDRESSES.Router, ROUTER_ABI, withSignerIfPossible);
}

export function useTreasuryContract(
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(
    PURPED_ADDRESSES.Treasury,
    TREASURY_ABI,
    withSignerIfPossible
  );
}

export function usePoolContract(
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(PURPED_ADDRESSES.Pool, POOL_ABI, withSignerIfPossible);
}

export function useTradingRRPContract(
  withSignerIfPossible?: boolean
): Contract | null {
  let _abi = tradingRrpABI;
  let _address = PURPED_ADDRESSES.TradingRRP;

  return useContract(_address, _abi, withSignerIfPossible);
}

export function useStocksTradingRRPContract(
  withSignerIfPossible?: boolean
): Contract | null {
  let _abi = stockTradingRrpABI;
  let _address = PURPED_ADDRESSES.StockTradingRRP;

  return useContract(_address, _abi, withSignerIfPossible);
}

export function useCryptoTradingRRPContract(
  withSignerIfPossible?: boolean
): Contract | null {
  let _abi = cryptoTradingRrpABI;
  let _address = PURPED_ADDRESSES.CryptoPerped;
  return useContract(_address, _abi, withSignerIfPossible);
}
export function useCryptoIndexTradingRRPContract(
  withSignerIfPossible?: boolean
): Contract | null {
  let _abi = cryptoTradingRrpABI;
  let _address = PURPED_ADDRESSES.CryptoTradingRRP;
  return useContract(_address, _abi, withSignerIfPossible);
}

export function useAirdnodeRRPContract(
  withSignerIfPossible?: boolean
): Contract | null {
  //: fix trading rpp abi
  return useContract(
    PURPED_ADDRESSES.AirnodeRrpV0,
    airnodeRrpABI,
    withSignerIfPossible
  );
}

export function useApi3PublicAirnodeontract(
  withSignerIfPossible?: boolean
): Contract | null {
  //: fix trading rpp abi
  return useContract(API3_AIRNODE, airnodeRrpABI, withSignerIfPossible);
}

export function useRequesterContract(
  withSignerIfPossible?: boolean
): Contract | null {
  //: fix requester abi
  return useContract(
    PURPED_ADDRESSES.Requester,
    POOL_ABI,
    withSignerIfPossible
  );
}
