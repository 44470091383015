// import React from "react";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { usePrevious } from "react-use";
import { formatCurrency } from "../../utils/helper";

// const chartEvent = (
//   event,
//   chartConfig,
//   { seriesIndex, dataPointIndex, config }
// ) => {
//   if (dataPointIndex < 0) {
//     return;
//   } else {
//     // console.log("click on data ", dataPointIndex);
//   }
// };

const AreaChart = ({ chartData }) => {
  let state = {
    options: {
      chart: {
        id: "chart2",
        type: "area",
        height: 305,
        foreColor: "#EBC7E6",
        toolbar: {
          show: false,
        },
        events: {
          mouseMove: () => {},
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      colors: ["#645CBB", "#A084DC", "#BFACE2", "#EBC7E6"],
      grid: {
        borderColor: "#212121",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        tickAmount: 3,
        labels: {
          formatter: function (value) {
            return formatCurrency(value);
          },
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
        y: {},
        theme: "dark",
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
    },

    selection: "all",
    series: [
      {
        name: "Price USD",
        data: [],
      },
    ],
  };

  // pointer to the chart object
  const chartCreated = false;
  // const [chartCreated, setChartCreated] = useState(false);
  const dataPrev = usePrevious(chartData);
  const [currChartData, setChartData] = useState(state.series);

  const currentChartDataPoint = (item) => {
    return [item.Mod_Date, item.Value];
  };
  useEffect(() => {
    if (chartData !== dataPrev && chartData) {
      const _data =
        chartData.length > 0
          ? chartData.map((item) => currentChartDataPoint(item))
          : [];

      setChartData([{ name: currChartData[0].name, data: _data }]);
    }
  }, [chartCreated, chartData, dataPrev, currChartData]);

  return (
    <Chart
      options={state.options}
      series={currChartData}
      type="area"
      height={305}
    />
  );
};

export default AreaChart;
