import {
  GET_CLOSED_ORDERS,
  GET_ENTRY_PRICE,
  GET_EXACT_IN,
  GET_EXACT_OUT,
  GET_FULFILLED_ORDERS,
  GET_OPEN_ORDERS,
  SELECT_TOKEN0,
  SELECT_TOKEN1,
  SET_ASSET_TYPE,
  SET_LEVERAGE,
  SET_LOADING,
  SET_TRADING_INFO,
  UPDATE_OPEN_ORDER,
} from "../actions/types";
import { ASSET_TYPE } from "../constants/index";

const initalState = {
  tokenPrices: {},
  error: null,
  bestTradeExactIn: "",
  bestTradeExactOut: "",
  tradingInfo: {
    fee: "",
    intereset: "",
    liquidationThresold: "",
    maxLeverage: "",
  },
  sponserWallet: {}, // account: sponserWalletAddreess
  sponsershipStatus: {}, // account: status  true / false
  pendingTrades: [],
  completedTrades: [],
  closedOrders: [],
  btcPrice: {},
  token0: {},
  token1: {},
  leverage: 1,
  loading: false,
  assetType: ASSET_TYPE.STOCK,
};

function tradeReducer(state = initalState, action) {
  // todo design and write action types and state updates
  switch (action.type) {
    case GET_ENTRY_PRICE:
      const tokenPrice = action.payload.priceObj;
      const btcPrice = action.payload.btcPrice;
      return {
        ...state,
        tokenPrices: { ...state.tokenPrices, ...tokenPrice },
        btcPrice: btcPrice,
      };

    case GET_EXACT_OUT:
      return {
        ...state,
        bestTradeExactOut: action.payload,
      };
    case GET_EXACT_IN:
      return {
        ...state,
        bestTradeExactIn: action.payload,
      };
    case SET_TRADING_INFO:
      return {
        ...state,
        tradingInfo: action.payload,
      };
    case GET_OPEN_ORDERS:
      return {
        ...state,
        pendingTrades: action.payload,
      };
    case UPDATE_OPEN_ORDER:
      return {
        ...state,
        pendingTrades: !action.payload ? [] : [action.payload],
      };
    case GET_FULFILLED_ORDERS:
      return {
        ...state,
        completedTrades: !action.payload ? [] : action.payload,
      };
    case GET_CLOSED_ORDERS:
      return {
        ...state,
        closedOrders: !action.payload ? [] : action.payload,
      };
    case SELECT_TOKEN0:
      return {
        ...state,
        token0: action.payload,
      };
    case SELECT_TOKEN1:
      return {
        ...state,
        token1: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ASSET_TYPE:
      return {
        ...state,
        assetType: action.payload,
      };
    case SET_LEVERAGE:
      return {
        ...state,
        leverage: action.payload,
      };
    default:
      return state;
  }
}

export default tradeReducer;
