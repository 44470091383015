import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency } from "../../utils/helper";
import BigNumber from "bignumber.js";
import { makeStyles } from "@mui/styles";
import { ASSET_TYPE } from "../../constants";
import { SET_LEVERAGE } from "../../actions/types";

const useStyles = makeStyles((theme) => ({
  titleText: {
    color: "#4369B0",
    fontSize: 13,
    padding: 0,
    letterSpacing: 1.1,
  },
  descText: {
    color: "#ffffff",
    fontSize: 13,
    padding: 0,
    letterSpacing: 1.1,
  },
  mark: {
    color: "red",
    // fontSize: 13,
    // padding: 0,
    // letterSpacing: 1.1,
  },
}));

const marks = [
  {
    value: 1,
    label: "1x",
  },
  // {
  //   value: 2,
  //   label: "2x",
  // },
  {
    value: 5,
    label: "5x",
  },
  {
    value: 10,
    label: "10x",
  },
  {
    value: 15,
    label: "15x",
  },
  {
    value: 20,
    label: "20x",
  },
  {
    value: 25,
    label: "25x",
  },
  {
    value: 30,
    label: "30x",
  },
];

export default function TradeInfo({
  token2,
  token1Amount,
  token2Amount,
  isLong,
}) {
  const classes = useStyles();

  const [leverage, setLeverate] = useState(1);
  function valuetext(value) {
    return `${value}x`;
  }
  const dispatch = useDispatch();

  const tokenPrices = useSelector((state) => state?.trade?.tokenPrices);
  const productInfo = useSelector((state) => state?.trade?.tradingInfo);

  const leverageSliderHandle = (e) => {
    console.log("slider value ", e.target.value);
    setLeverate(e.target.value);
    dispatch({
      type: SET_LEVERAGE,
      payload: e.target.value,
    });
  };

  const liquidationPrice = useMemo(() => {
    if (!tokenPrices?.[token2?.symbol] || !productInfo?.liquidationThreshold) {
      return;
    }
    const price = new BigNumber(tokenPrices?.[token2?.symbol]);
    let liqThresold = new BigNumber(productInfo?.liquidationThreshold).div(
      token2?.type === ASSET_TYPE.STOCK ? 10000 : 1000
    );

    if (!isLong) {
      return price.multipliedBy(liqThresold)?.toString();
    }
    return price.div(liqThresold)?.toString();
  }, [tokenPrices, productInfo, token2, isLong]);

  const fee = useMemo(() => {
    // fee = ((fee value from getProduct) / (10**6)) x (AMOUNT_OF_MATIC x $MATIC)
    if (!tokenPrices?.[token2?.symbol] || !token2Amount || !productInfo?.fee) {
      return;
    }
    const price = tokenPrices?.[token2?.symbol];
    let fee = new BigNumber(productInfo?.fee);
    fee = fee?.div(10000000);
    fee = fee?.multipliedBy(token2Amount)?.multipliedBy(price)?.toString();
    return fee;
  }, [tokenPrices, productInfo, token2, token2Amount]);

  return (
    <Box>
      <Box width={"97%"}>
        <Slider
          aria-label="Custom marks"
          onChange={leverageSliderHandle}
          getAriaValueText={valuetext}
          valueLabelFormat={valuetext}
          step={1}
          min={1}
          max={30}
          defaultValue={leverage}
          valueLabelDisplay="auto"
          marks={marks}
          color="primary"
        />
      </Box>

      <Box
        width={"100%"}
        display={"flex"}
        justifyContent="space-between"
        mt={2}
      >
        <h6 className={classes.titleText}>Collateral in</h6>
        <h6 className={classes.descText}>USD</h6>
      </Box>
      <Box width={"100%"} display={"flex"} justifyContent="space-between">
        <h6 className={classes.titleText}>Leverage</h6>
        <h6 className={classes.descText}>{leverage}x</h6>
      </Box>
      <Box width={"100%"} display={"flex"} justifyContent="space-between">
        <h6 className={classes.titleText}>Entry Price</h6>
        <h6 className={classes.descText}>
          ${formatCurrency(tokenPrices?.[token2?.symbol])}
        </h6>
      </Box>
      <Box width={"100%"} display={"flex"} justifyContent="space-between">
        <h6 className={classes.titleText}>Liq. Price</h6>
        <h6 className={classes.descText}>
          ${formatCurrency(liquidationPrice, true)}
        </h6>
      </Box>
      <Box width={"100%"} display={"flex"} justifyContent="space-between">
        <h6 className={classes.titleText}>Fees</h6>
        <h6 className={classes.descText}>$ {formatCurrency(fee)}</h6>
      </Box>
    </Box>
  );
}
