import { useCallback, useEffect, useMemo } from "react";
import useBlockNumber from "./useBlockNumber";
import useActiveWeb3React from "./useActiveWeb3React";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CLOSED_ORDERS,
  GET_FULFILLED_ORDERS,
  GET_OPEN_ORDERS,
  UPDATE_OPEN_ORDER,
} from "../actions/types";

import { gql, useLazyQuery } from "@apollo/client";
import {
  getClosedOrderQueries,
  getFullFilledOrderQueries,
  getOpenOrderQuery,
} from "../apollo/queries";
import { ASSET_TYPE } from "../constants";

export function useOrders(): {
  isPendingLoading: Boolean;
  isCloseLoading: Boolean;
  isCompletedLoading: Boolean;
  error: any;
  loadPendingOrder: () => void;
  loadCompletedOrders: () => void;
  loadClosedOrders: () => void;
} {
  const { account } = useActiveWeb3React();
  const dispatch = useDispatch();

  const token1 = useSelector((state: any) => state?.trade?.token1);

  const pendingOrderQuery = useMemo(() => {
    return getOpenOrderQuery(account, token1?.productId, token1?.type);
  }, [token1, account]);

  const [
    getPendingOrder,
    { loading: pendingLoading, error: ordersError, data: pendingOrderData },
  ] = useLazyQuery(gql(pendingOrderQuery), {
    fetchPolicy: "network-only",
    pollInterval: 3000,
  });

  //long orders
  const LONG = true;
  const completedLongQuery = useMemo(() => {
    return getFullFilledOrderQueries(
      account,
      token1?.productId,
      LONG,
      token1?.type
    );
  }, [account, token1]);

  //short orders
  const completedShortQuery = useMemo(() => {
    return getFullFilledOrderQueries(
      account,
      token1?.productId,
      !LONG,
      token1?.type
    );
  }, [account, token1]);

  // closed long orders
  const closedLongQuery = useMemo(() => {
    return getClosedOrderQueries(
      account,
      token1?.productId,
      LONG,
      token1?.type
    );
  }, [account, token1, LONG]);

  // closed short orders
  const closedShortQuery = useMemo(() => {
    return getClosedOrderQueries(
      account,
      token1?.productId,
      !LONG,
      token1?.type
    );
  }, [account, token1, LONG]);

  const [
    getCompleted,
    { loading: completedLoading, error: error, data: completedData },
  ] = useLazyQuery(gql(completedLongQuery), {
    fetchPolicy: "network-only",
    pollInterval: 3000,
  });

  const [
    getCompleted2,
    { loading: completedLoaading2, error: error2, data: completedData2 },
  ] = useLazyQuery(gql(completedShortQuery), {
    fetchPolicy: "network-only",
    pollInterval: 3000,
  });

  const [
    getLongClosed,
    { loading: closeLoading, error: closeError, data: closedItems },
  ] = useLazyQuery(gql(closedLongQuery), {
    fetchPolicy: "network-only",
    pollInterval: 3000,
  });

  const [
    getShortClosed,
    { loading: closeLoading2, error: closeError2, data: closedItems2 },
  ] = useLazyQuery(gql(closedShortQuery), {
    fetchPolicy: "network-only",
    pollInterval: 3000,
  });

  const isCompletedLoading = useMemo(() => {
    return completedLoading || completedLoaading2;
  }, [completedLoading, completedLoaading2]);

  const isCloseLoading = useMemo(() => {
    return closeLoading || closeLoading2;
  }, [closeLoading, closeLoading2]);

  // pending orders watch
  useEffect(() => {
    console.log("pending data ", pendingOrderData);
    if (!pendingOrderData?.submittedOrders?.length) {
      dispatch({
        type: GET_OPEN_ORDERS,
        payload: [],
      });
      return;
    }

    let orders = pendingOrderData?.submittedOrders;

    // load pending order from local json
    let pendingOrders: any = localStorage.getItem(
      `pending_orders${token1?.productId}`
    );
    pendingOrders = pendingOrders ? JSON.parse(pendingOrders) : null;

    console.log("cached pending", pendingOrders);
    if (pendingOrders && pendingOrders?.[0]?.completed === false) {
      if (pendingOrders?.[0]?.hash === orders?.[0]?.hash) {
        dispatch({
          type: UPDATE_OPEN_ORDER,
          payload: { ...pendingOrders?.[0], completed: orders?.[0]?.completed },
        });
      } else {
        dispatch({
          type: GET_OPEN_ORDERS,
          payload: [],
        });
      }
      return;
    } else if (pendingOrders && pendingOrders?.[0]?.completed === true) {
      dispatch({
        type: GET_OPEN_ORDERS,
        payload: [],
      });
    }
  }, [pendingOrderData, token1]);

  const fulfilledOrders = useMemo(() => {
    let orders: any = [];

    if (
      !completedData?.completedOrders?.length &&
      !completedData2?.completedOrders?.length
    ) {
      // dispatch({ type: GET_FULFILLED_ORDERS, payload: [] });
      return orders;
    }

    if (
      !completedData?.completedOrders?.length &&
      completedData2?.completedOrders?.length
    ) {
      orders = completedData2?.completedOrders;
      // dispatch({ type: GET_FULFILLED_ORDERS, payload: orders });
      return orders;
    }

    if (
      !completedData2?.completedOrders?.length &&
      completedData?.completedOrders?.length
    ) {
      orders = completedData?.completedOrders;
      // dispatch({ type: GET_FULFILLED_ORDERS, payload: orders });
      return orders;
    }

    if (
      completedData?.completedOrders?.[0]?.date >
      completedData2?.completedOrders?.[0]?.date
    ) {
      orders = [
        ...completedData?.completedOrders,
        ...completedData2?.completedOrders,
      ];
    } else {
      orders = [
        ...completedData2?.completedOrders,
        ...completedData?.completedOrders,
      ];
    }

    return orders;
  }, [completedData, completedData2, token1]);

  // fulfilled orders watch
  useEffect(() => {
    if (fulfilledOrders?.length) {
      // filter out closed fulfilled orders
      const filtered = fulfilledOrders?.filter((el: any) => !el.isClosed);
      dispatch({ type: GET_FULFILLED_ORDERS, payload: filtered });
    }
  }, [fulfilledOrders]);

  const closedOrders = useMemo(() => {
    if (
      !closedItems?.closedOrders?.length &&
      !closedItems2?.closedOrders?.length
    ) {
      return [];
    }

    if (
      closedItems?.closedOrders?.length > 0 &&
      !closedItems2?.closedOrders?.length
    ) {
      return closedItems?.closedOrders;
    }

    if (
      !closedItems?.closedOrders?.length &&
      closedItems2?.closedOrders?.length > 0
    ) {
      return closedItems2?.closedOrders;
    }

    if (
      closedItems?.closedOrders?.[0]?.date >
      closedItems2?.closedOrders?.[0]?.date
    ) {
      const order1 = closedItems?.closedOrders;
      const order2 = closedItems2?.closedOrders;
      // console.log("order test 1", { order1, order2 });
      return [...order1, ...order2];
    } else {
      const order1 = closedItems?.closedOrders;
      const order2 = closedItems2?.closedOrders;
      // console.log("order test 2", { order1, order2 });
      return [...order2, ...order1];
    }
  }, [closedItems, closedItems2]);
  // closed orders watch

  useEffect(() => {
    console.log("update test query test data fetched ", {
      fulfilledOrders,
      closedOrders,
      closedItems,
      closedItems2,
      pendingOrderData,
    });
  }, [
    pendingOrderData,
    fulfilledOrders,
    closedOrders,
    closedItems,
    closedItems2,
  ]);

  useEffect(() => {
    console.log("update test query test data fetched", { closedOrders });
    if (closedOrders?.length) {
      // const filtered = fulfilledOrders?.filter((item: any) => !item?.isClosed);
      // dispatch({ type: GET_FULFILLED_ORDERS, payload: filtered });
      dispatch({ type: GET_CLOSED_ORDERS, payload: closedOrders });
    }
  }, [fulfilledOrders, closedOrders, dispatch]);

  function loadPendingOrder() {
    getPendingOrder();
  }
  function loadCompletedOrders() {
    console.log("update test calling fn to load");
    getCompleted();
    getCompleted2();
  }

  function loadClosedOrders() {
    getLongClosed();
    getShortClosed();
  }

  return {
    isPendingLoading: pendingLoading,
    isCloseLoading: isCloseLoading,
    isCompletedLoading: isCompletedLoading,
    error: closeError,
    loadPendingOrder: loadPendingOrder,
    loadCompletedOrders: loadCompletedOrders,
    loadClosedOrders: loadClosedOrders,
  };
}
