import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Container } from "@mui/material";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import { useDispatch, useSelector } from "react-redux";
import { useOrderCallback } from "../../hooks/useOrderCallback";
import PopupLayout from "../../common/popups/PopupLayout";
import TxPopup from "../../common/popups/TxPopup";
import { ASSET_TYPE } from "../../constants";
import { useTradingAccount } from "../../hooks/useTradingAccount";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#000000",
    backgroundImage: `url("images/background.png")`,
    backgroundSize: "cover",
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    paddingTop: "3%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingTop: "10%",
    },
  },
  para: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: "0.02em",
    color: "#414141",
    textAlign: "center",
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20%",
  },
}));

export default function Faucet() {
  const classes = useStyles();
  const query = useParsedQueryString();
  const dispatch = useDispatch();
  const transactionStatus = useSelector((state) => state?.transaction);
  const assetType = useSelector((state) => state?.trade?.assetType);

  const {
    isAccountCreated,
    sponsorshipStatus,
    loadingTradeState,
    createSponsorWallet,
    setSponsorshipStatus,
  } = useTradingAccount();

  const { claimFaucet, resetTrxState } = useOrderCallback();

  const handleModalClose = useCallback(() => {
    resetTrxState();
  }, [resetTrxState, transactionStatus]);

  const handleClaim = useCallback(async () => {
    if (!sponsorshipStatus) {
      setSponsorshipStatus();
      return;
    }
    claimFaucet(assetType);
  }, [claimFaucet, transactionStatus, assetType, sponsorshipStatus]);

  return (
    <Box className={classes.background}>
      <PopupLayout popupActive={transactionStatus?.state > 0}>
        <TxPopup
          type={transactionStatus.type}
          txCase={transactionStatus?.state}
          hash={transactionStatus?.hash}
          resetPopup={handleModalClose}
        />
      </PopupLayout>
      <Box>
        <Container>
          <Box className={classes.buttonWrapper}>
            <Button
              disabled={assetType === ASSET_TYPE.STOCK}
              onClick={handleClaim}
              style={{
                marginTop: 10,
                backgroundColor: "#1C1F23",
                color: "#4369B0",
                width: 500,
                textTransform: "none",
                fontSize: 14,
                fontWeight: 400,
                borderRadius: 10,
                willChange: "transform",
                transition: "transform 450ms ease 0s",
                transform: "perspective(1px) translateZ(0px)",
                padding: "10px 15px 10px 15px",
                cursor: "pointer",
              }}
            >
              {sponsorshipStatus
                ? `Claim faucet for ${assetType} Trades`
                : "Sponsor wallet"}
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
