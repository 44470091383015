export const GET_ERRORS = "GET_ERRORS";

//list reducers
export const GET_TOKENS = "GET_TOKENS";

//trade
export const GET_ENTRY_PRICE = "GET_ENTRY_PRICE";
export const GET_EXACT_OUT = "GET_EXACT_OUT";
export const GET_EXACT_IN = "GET_EXACT_IN";
export const SET_SPONSER_WALLET = "SET_SPONSER_WALLET";
export const SET_SPONSER_STATUS = "SET_SPONSER_STATUS";
export const SET_TRADING_INFO = "SET_TRADING_INFO";
export const SET_ASSET_TYPE = "SET_ASSET_TYPE";
export const SET_LEVERAGE = "SET_LEVERAGE";

// trade reduces for buy/sell order transactions
export const CREATE_TRADE_LOADING = "CREATE_TRADE_LOADING";
export const FETCH_TRADE_LOADING = "FETCH_TRADE_LOADING";
export const CREATE_TRADE = "CREATE_TRADE";

export const SET_TRADE_ERROR = "SET_TRADE_ERROR";
export const TRADE_LOADING = "TRADE_LOADING";

export const GET_OPEN_ORDERS = "GET_OPEN_ORDERS";
export const UPDATE_OPEN_ORDER = "UPDATE_OPEN_ORDER";
export const GET_FULFILLED_ORDERS = "GET_FULFILLED_ORDERS";
export const GET_CLOSED_ORDERS = "GET_CLOSED_ORDERS";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const SELECT_TOKEN0 = "SELECT_TOKEN0";
export const SELECT_TOKEN1 = "SELECT_TOKEN1";
export const UPDATE_PNL = "UPDATE_PNL";
export const SET_LOADING = "SET_LOADING";

// transaction states
export const START_TRANSACTION = "START_TRANSACTION";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const RESET_TRANSACTION = "RESET_TRANSACTION";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";
