export const NetworkContextName = "NETWORK";
export const WALLET_SUPORTED_ON_CHAINS = [
  1, 4, 5, 1285, 1287, 97, 56, 137, 80001, 1666700000, 1666600000,
];

export const FALLBACK_DEFAULT_CHAIN = 5; // Todo change this for release
export const DAPP_SUPPORTED_ON_CHAINS = [5];
export const FALLBACK_DEFAULT_FIAT = "INR";

export const ALLOWANCE_AMOUNT = "999999999";

export const P2P_TRADE_FEE = 0.25;

export const PURPED_ADDRESSES = {
  Router: "0xc3Ea3587D5D13F8D0CF9144F2849623099214bcC",
  Treasury: "0x00eA375e03632D29A7253855E512fea9fa48844B",
  AirnodeRrpV0: "0x5656Bcc9b155539a466c0AdcC44C3E5b083511f8",
  Requester: "0x9570850c50D710AbE49BA3069BBD3256DE48e58B",
  TradingRRP: "0x986F0A9BC49ac70dB522714Ed39FEf14347e3a4C",
  StockTradingRRP: "0x012802281C1b04c7aed146B8C2964A970DDacE26",
  CryptoTradingRRP: "0x2650c38F6c7358cA8fFe6C2E4D2cFd09a10dB4f6",
  CryptoPerped: "0x10F489855FeA09ef21ec6B2Ad6F705573fa1a3dC",
  Pool: "0xb1ea343a6b81f3eA933bCC09D380eBdb16142154",
  PoolUSDC: "0xaaCD720b0a2E4EE37eD8f7F7112D7D8acCf98228",
  PoolPRPD: "0x830d822811D9C1a4d4767d3654782Ef452fEEe96",
  PoolRewards: "0x4267DbBa69ba9613Af00A2dc97E0CfA773fA5578",
  PoolRewardsUSDC: "0xA4CFE1A9d7d52EC483629CC309BabD609d4b84EC",
  PRPDRewards: "0x07de3478374CC26FD4Fcc1b33f2d77486B20D860",
  PRPDRewardsUSDC: "0x421D682dF49275cC3262c5d0b76aF8855B57d79E",
};

export const API3_AIRNODE = "0xa0AD79D995DdeeB18a14eAef56A549A04e3Aa1Bd";
export const CRYPTO_INDEX_AIRNODE =
  "0x02E33a1405fd8f46158F256DF8781339c63e6858";

export const AIRNODE_XPUB =
  "xpub6By136nPCDgE9mfgMs4VfE5pzoPajhGXT2qq4rhzcWrBrPdbf8PPwUCj1LyxATa6o8cAHhhrTEJg15eyrMS8f5kvgwzU22wQFwDJ7Ez9zAe";

export const AIRNODE_XPUB_STOCKS =
  "xpub6CeoWe3yV6yiewhdpfXDcj4JURNar7yuD95AtS6eba18z6aZaXzKKrVH9bNb8nMDptUA4vcAEr6HoYEMCpNwsGCeVqQhvXsHLJheVF8K6VM";

export const TRADE_PRODUCT_NAMES = {
  BATCH04: "Cobalt Hydroxide CIF",
  BATCP04: "Lithium Carbonate CIF",
  CNCAD00: "Platts CNC",
  ACRCA00: "Platts CRC",
};

export const WETH_ADDRESS = {
  1: "",
  4: "0xc778417e063141139fce010982780140aa0cd5ab",
  5: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
  56: "",
  97: "0xae13d989dac2f0debff460ac112a837c89baa7cd", // bnb for bsc chain
};

export const CONNECTOR_TYPE = {
  injected: "injected",
  walletConnect: "walletConnect",
  unstoppable: "unstoppable",
};

export const NETWORK_TYPE = 1; // testing:1, mainnet:0

export const NATIVE_TOKENS = {
  4: {
    name: "Ether",
    decimals: 18,
    symbol: "ETH",
    address: "",
    chainId: 5,
  },
  5: {
    name: "Ether",
    decimals: 18,
    symbol: "ETH",
    address: "",
    chainId: 5,
  },
  97: {
    name: "Binance Token",
    decimals: 18,
    symbol: "BNB",
    address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    chainId: 97,
  },
  137: {
    name: "Matic",
    decimals: 18,
    symbol: "MATIC",
    address: "",
    chainId: 137,
  },
};

export const TRADE_TYPE = {
  long: "long",
  short: "short",
  swap: "swap",
};

export const PRODUCT_ID =
  "0x4554482d55534400000000000000000000000000000000000000000000000000";

export const TRADE_FN = {
  swapExactETHForTokens: "swapExactETHForTokens", // case 1
  swapETHforExactTokens: "swapETHforExactTokens", // case2
  swapExactTokensForETH: "swapExactTokensForETH", // case3
  swapTokensForExactETH: "swapTokensForExactETH", // case 4
  swapExactTokensForTokens: "swapExactTokensForTokens", // case 5
  swapTokensForExactTokens: "swapTokensForExactTokens", // case 6
  openLongExactIn: "openLongExactIn", // tradeType case 2
  closeLongExactIn: "closeLongExactIn", // tradeType case 2
  openShortExactIn: "openShortExactIn", // tradeType case 2
  closeShortExactIn: "closeShortExactIn", // tradeType case 2
};

export const TRADE_INPUT_ORDER = {
  exactIn: "exactIn", // tradeType case 1
  exactOut: "exactOut", // tradeType case 2
};

export const hashToName = {
  "0x4241544348303400000000000000000000000000000000000000000000000000":
    "Cobalt Hydroxide",
  "0x4241544350303400000000000000000000000000000000000000000000000000":
    "Lithium Carbonate",
  "0x4143524341303000000000000000000000000000000000000000000000000000":
    "Platts CNC",
  "0x434e434144303000000000000000000000000000000000000000000000000000":
    "Platts CRC",
};

export const productHashToId = {
  "0x4241544348303400000000000000000000000000000000000000000000000000":
    "BATCH04",
  "0x4241544350303400000000000000000000000000000000000000000000000000":
    "BATCP04",
  "0x4143524341303000000000000000000000000000000000000000000000000000":
    "CNCAD00",
  "0x434e434144303000000000000000000000000000000000000000000000000000":
    "ACRCA00",
};

export const ASSET_TYPE = {
  PLATTS: "PLATTS",
  STOCK: "STOCK",
  CRYPTO: "CRYPTO",
  CRYPTO_INDEX: "CRYPTO_INDEX",
};

export const ACTIVE_ASSETS = ASSET_TYPE.STOCK;
