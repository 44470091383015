import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OrderTableRow from "./OrderTableRow";
import { useSelector } from "react-redux";
import { useOrderCallback } from "../../hooks/useOrderCallback";
import {
  useCryptoTradingRRPContract,
  useStocksTradingRRPContract,
  useTradingRRPContract,
} from "../../hooks/useContract";
import { ASSET_TYPE } from "../../constants";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 10,
  },
  tableCard: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    paddingTop: 2.5,
    paddingBottom: 10,
    minHeight: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  loading: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    backgroundColor: "#FFFFFF",
    opacity: "0.6",
    zIndex: 10,
  },
  avatar: {
    marginRight: 8,
    borderRadius: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 14,
    color: "#EAECEE",
  },
  table: {
    width: "100%",
  },
  // tr: {
  //   width: "100%",
  //   backgroundColor:'transparent'
  // },
  // trHighlight: {
  //   width: "100%",
  //   backgroundColor:'transparent',
  //   borderRadius: 10,
  //   paddingTop: 10,
  //   paddingBottom: 10,
  // },
  // userText: {
  //   fontSize: 13,
  //   fontWeight: 500,
  //   color: "#1943DB",
  // },
  orderText: {
    fontSize: 13,
    fontWeight: 400,
  },
  // otherText: {
  //   fontSize: 13,
  //   fontWeight: 400,
  //   [theme.breakpoints.down("md")]: {
  //     fontWeight: 400,
  //     fontSize: 12,
  //   },
  // },
  orderTab: {
    backgroundColor: "#EEEEEE",
    padding: "5px 15px 5px 15px",
    fontWeight: 600,
  },
  orderTabSelected: {
    backgroundColor: "#DF097C",
    padding: "5px 15px 5px 15px",
    color: "white",
    fontWeight: 600,
  },
  tableHeading: {
    fontSize: 13,
    fontWeight: 600,
  },
  buttonAction: {
    backgroundColor: "green",
    border: `1px solid #6A55EA`,
    borderRadius: 14,
  },
}));

export default function OrderTable({
  orders,
  page,
  changePage,
  totalPages,
  currentTab,
  loading,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.down("md"));

  const assetType = useSelector((state) => state?.trade?.assetType);
  const { closeOrder } = useOrderCallback();

  const tradingContract = useTradingRRPContract();
  const stocksTradingContract = useStocksTradingRRPContract();
  const cryptoTradingContract = useCryptoTradingRRPContract();
  const handleClosePosition = useCallback(
    (positionData) => {
      let contract = tradingContract;

      if (assetType === ASSET_TYPE.STOCK) {
        contract = stocksTradingContract;
      } else if (assetType === ASSET_TYPE.CRYPTO) {
        contract = cryptoTradingContract;
      }

      closeOrder(
        positionData?.productId,
        positionData?.size,
        positionData?.currency,
        positionData?.margin,
        positionData?.isLong,
        assetType,
        positionData.id,
        contract
      );
    },
    [assetType, tradingContract, stocksTradingContract, cryptoTradingContract]
  );

  return (
    <Box>
      <Box className={classes.tableCard}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th style={{ paddingRight: "5px" }}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  color={"#4369B0"}
                  fontSize={md ? 11 : 13}
                  // pl={1}
                  style={{ fontWeight: 500 }}
                >
                  Position
                </Typography>
              </th>
              <th style={{ padding: "5px " }}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  color={"#4369B0"}
                  fontSize={md ? 11 : 13}
                  fontFamily="Source Code Pro"
                  // pl={1}
                  style={{ fontWeight: 500 }}
                >
                  Asset
                </Typography>
              </th>
              <th style={{ padding: "5px " }}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  color={"#4369B0"}
                  fontSize={md ? 11 : 13}
                  style={{ fontWeight: 500 }}
                >
                  Leverage
                </Typography>
              </th>
              <th style={{ padding: "5px " }}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  color={"#4369B0"}
                  fontSize={md ? 11 : 13}
                  style={{ fontWeight: 500 }}
                >
                  Entry Price
                </Typography>
              </th>
              <th style={{ padding: "5px " }}>
                <Typography
                  textAlign="left"
                  variant="body2"
                  color={"#4369B0"}
                  fontSize={md ? 11 : 13}
                  style={{ fontWeight: 500 }}
                >
                  Liq. price
                </Typography>
              </th>
              <th style={{ padding: "5px " }}>
                <Typography
                  variant="body2"
                  color={"#4369B0"}
                  fontSize={md ? 11 : 13}
                  style={{ fontWeight: 500 }}
                >
                  Margin
                </Typography>
              </th>

              <th style={{ padding: "5px " }}>
                <Typography
                  variant="body2"
                  color={"#4369B0"}
                  fontSize={md ? 11 : 12}
                  style={{ fontWeight: 500 }}
                >
                  Fee
                </Typography>
              </th>
              <th style={{ padding: "5px " }}>
                <Typography
                  variant="body2"
                  color={"#4369B0"}
                  fontSize={md ? 11 : 13}
                  style={{ fontWeight: 500 }}
                >
                  Pnl
                </Typography>
              </th>

              {currentTab === 0 && (
                <th style={{ padding: "5px " }}>
                  <Typography
                    variant="body2"
                    color={"#4369B0"}
                    fontSize={md ? 11 : 13}
                    style={{ fontWeight: 500 }}
                  ></Typography>
                </th>
              )}
            </tr>
          </thead>
          {loading && (
            <div
              style={{
                width: "100%",
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={25} />
            </div>
          )}
          {!loading &&
            orders?.map((order, index) => {
              return (
                <OrderTableRow
                  key={order.id}
                  data={order}
                  index={index}
                  handleClosePosition={handleClosePosition}
                  currentTab={currentTab}
                />
              );
            })}
        </table>
        {/* {totalPages > 1 && (
            <Pagination
              page={page}
              changePage={changePage}
              totalPages={totalPages}
            />
          )} */}
      </Box>
    </Box>
  );
}
