import { Box, Button, Grid, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TradeInfo from "./TradeInfo";
import TradeInputGroup from "./TradeInputGroup";
import {
  getEntryPrice,
  getExactIn,
  getExactOut,
} from "../../actions/tradeAction";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useTokenAllowance } from "../../hooks/useAllowance";
import {
  ALLOWANCE_AMOUNT,
  ASSET_TYPE,
  CONNECTOR_TYPE,
  PURPED_ADDRESSES,
  TRADE_FN,
  TRADE_INPUT_ORDER,
  TRADE_TYPE,
} from "../../constants";
import {
  formatCurrency,
  fromWei,
  isMetaMaskInstalled,
  toWei,
} from "../../utils/helper";
import { useTokenContract } from "../../hooks/useContract";
import { useOrderCallback } from "../../hooks/useOrderCallback";
import { useTradingAccount } from "../../hooks/useTradingAccount";
import PopupLayout from "../popups/PopupLayout";
import TxPopup from "../popups/TxPopup";
import { useUserAuthentication } from "../../hooks/useUserAuthentication";
import Orders from "../Orders/Orders";
import Chart from "../Chart/Chart";
import BigNumber from "bignumber.js";
import { SELECT_TOKEN0, SELECT_TOKEN1 } from "../../actions/types";
import { useCurrencyBalance } from "../../hooks/useBalance";
import LiveChart from "../Chart/LiveChart";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    borderRadius: 10,
    boxShadow: `rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px`,
    backgroundColor: "transparent",
    padding: 10,
    marginTop: 50,
    // border: "1px solid #1C1F23",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 7,
      paddingRight: 7,
      width: "100%",
    },
  },
  buttonsContainer: {
    backgroundColor: "#1C1F23",
    height: 42,
    display: "flex",
    justifyContent: "space-evenly",
    borderRadius: 10,
    fontFamily: "Poppins",
    overflow: "hidden" /* Hide overflowing content when tabs slide */,
  },
  button: {
    height: "100%",
    color: "#4369B0",
    background: `transparent`,
    borderRadius: 10,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 15,
    cursor: "pointer",
    transition:
      "background 0.6s ease" /* Add transition to background property */,
  },
  buttonActive: {
    color: "#ffffff",
    height: "100%",
    background: `linear-gradient(105.62deg, #5F467B 21.35%, #4369B0 61.18%)`,
    borderRadius: 10,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 15,
    cursor: "pointer",
    transition: "background 0.6s ease" /* Add the same transition here */,
  },

  typeContainer: {
    paddingTop: 10,
    color: "#ffffff",
    display: "flex",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  type: {
    height: "100%",
    color: "#4369B0",
    paddingRight: 8,
    fontSize: 15,
    cursor: "pointer",
  },
  typeActive: {
    color: "#ffffff",
    paddingRight: 8,
    fontSize: 15,
  },
  inputContainer: {
    paddingTop: 15,
    width: "100%",
  },
  iconWrapper: {
    marginTop: 0,
    marginTop: -20,
    marginBottom: -20,
    display: "flex",
    justifyContent: "center",
  },
  smallHeading: {
    color: "#ffffff",
    fontSize: 13,
    marginTop: 12,
  },
  settingIcon: {
    color: "#6E67B6",
    fontSize: 22,
    cursor: "pointer",
    transition: "all 0.4s ease",
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  iconButton: {
    margin: 0,
    padding: 2,
    backgroundColor: "#02001F",
    borderRadius: "30%",
  },
  swapIcon: {
    color: "#6E67B6",
    fontSize: 28,
  },
  titleText: {
    color: "#4369B0",
    fontSize: 11,
    padding: 0,
    letterSpacing: 1.1,
  },
  descText: {
    color: "#ffffff",
    fontSize: 11,
    padding: 0,
    letterSpacing: 1.1,
  },
  pairContainer: {
    minHeight: 42,
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: 10,
    fontFamily: "Poppins",
  },
  pairBox: {
    width: "auto",
    borderColor: "#637392",
    borderWidth: "1px",
    borderStyle: "solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    padding: 10,
    borderRadius: 10,
    paddingLeft: 20,
    letterSpacing: 1.5,
    marginRight: 10,
  },
  ordersBox: {
    borderColor: "transparent",
    borderWidth: "1px",
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    justifyContent: "center",
    color: "white",
    padding: 14,
    borderRadius: 10,
    paddingLeft: 20,
    letterSpacing: 1.5,
    minWidth: "20%",
    marginRight: 10,
  },
  ordersTabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: 20,
  },
  tableTab: {
    color: "#ffffff",
    fontSize: 14,
    padding: 0,
    letterSpacing: 1.1,
    // borderBottom: "2px solid #4369B0",
    paddingBottom: 10,
    width: "fit-content",
    marginBottom: 10,
    marginRight: 20,
  },
  tableTabActive: {
    color: "#4369B0",
    fontSize: 14,
    padding: 0,
    letterSpacing: 1.1,
    borderBottom: "2px solid #4369B0",
    paddingBottom: 10,
    width: "fit-content",
    marginBottom: 10,
    marginRight: 10,
  },
  chartCard: {
    marginTop: 10,
    padding: 10,
    border: "1px solid #637392",
    borderRadius: 10,
    minHeight: 300,
  },
  titleTextBig: {
    color: "#4369B0",
    fontSize: 14,
    padding: 0,
    letterSpacing: 1.1,
    borderBottom: "2px solid #4369B0",
    paddingBottom: 10,
    width: "fit-content",
    marginBottom: 10,
  },
  linkText: {
    color: "#ffffff",
    fontSize: 14,
    padding: 0,
    letterSpacing: 1.1,
  },
  divider: {
    color: "#4369B0",
    border: "2px solid #4369B0",
    width: 100,
  },
}));

export default function Trade() {
  const classes = useStyles();
  const [selectedToken1, setToken1] = useState({});

  const [selectedToken2, setToken2] = useState({});
  const [token1Value, setToken1Value] = useState("");
  const [token2Value, setToken2Value] = useState("");
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useState(true);
  const [actionTab, setActionTab] = useState(0);
  const [tradeTypeTab, setTradeTypeTab] = useState(1);

  const dispatch = useDispatch();
  const { chainId, active, account } = useActiveWeb3React();

  const [currentFn, setCurrentFn] = useState({
    tradeFn: TRADE_FN.openLongExactIn,
    inputType: TRADE_INPUT_ORDER.exactIn,
  });
  const [tradeType, setTradeType] = useState(TRADE_TYPE.long);

  // const [isLong, setIsLong] = useState(false);
  const [isOpenTrade, setIsOpenTrade] = useState(true);

  const bestTradeExactOut = useSelector(
    (state) => state?.trade?.bestTradeExactOut
  );
  const bestTradeExactIn = useSelector(
    (state) => state?.trade?.bestTradeExactIn
  );

  const tokenPrices = useSelector((state) => state?.trade?.tokenPrices);
  const priceLoading = useSelector((state) => state?.trade?.loading);

  const tokens = useSelector((state) => state?.list.tokens);

  const { connectWallet } = useUserAuthentication(false);

  const isLong = useMemo(() => {
    return actionTab === 0 ? true : false;
  }, [actionTab]);

  const isSwap = useMemo(() => {
    return actionTab === 2 ? true : false;
  }, [actionTab]);

  const assetType = useSelector((state) => state?.trade?.assetType);

  const handleConnectWallet = useCallback(() => {
    if (isMetaMaskInstalled()) {
      connectWallet(CONNECTOR_TYPE.injected);
    } else {
      connectWallet(CONNECTOR_TYPE.walletConnect);
    }
  }, [connectWallet]);

  const onToken1Select = useCallback(
    (token) => {
      setToken1(token);
      setToken1Value("");
      setToken2Value("");
      dispatch({ type: SELECT_TOKEN0, payload: token });
    },
    [setToken1, dispatch]
  );

  const onToken2Select = useCallback(
    (token) => {
      setToken2(token);
      setToken1Value("");
      setToken2Value("");
      dispatch({ type: SELECT_TOKEN1, payload: token });
    },
    [setToken2, dispatch]
  );

  const handleSwapInputs = () => {
    const tokenSelected1 = selectedToken1;
    setToken1(selectedToken2);
    setToken2(tokenSelected1);
    setToken1Value("");
    setToken2Value("");
  };
  // token 1 input change
  const onToken1InputChange = async (tokens) => {
    setAnchorOnFromAmount(true);

    setToken1Value(tokens);

    setCurrentFn({
      tradeFn:
        tradeType === TRADE_TYPE.long
          ? TRADE_FN.openLongExactIn
          : TRADE_FN.openShortExactIn,
      inputType: TRADE_INPUT_ORDER.exactIn,
    });

    dispatch(
      getExactOut(
        tokens,
        tokenPrices?.[selectedToken1?.symbol],
        tokenPrices?.[selectedToken2?.symbol]
      )
    );
  };

  // token2 input change
  const onToken2InputChange = async (tokens) => {
    setAnchorOnFromAmount(false);
    setToken2Value(tokens);

    setCurrentFn({
      tradeFn:
        tradeType === TRADE_TYPE.long
          ? TRADE_FN.openLongExactIn
          : TRADE_FN.openShortExactIn,
      inputType: TRADE_INPUT_ORDER.exactOut,
    });

    dispatch(
      getExactIn(
        tokens,
        tokenPrices?.[selectedToken1?.symbol],
        tokenPrices?.[selectedToken2?.symbol]
      )
    );
  };

  const parsedToken1Value = useMemo(() => {
    return currentFn.inputType === TRADE_INPUT_ORDER.exactIn
      ? token1Value
      : !token2Value
      ? ""
      : bestTradeExactIn;
  }, [bestTradeExactIn, token1Value, token2Value, currentFn, selectedToken1]);

  const parsedToken2Value = useMemo(() => {
    return currentFn.inputType === TRADE_INPUT_ORDER.exactIn
      ? !token1Value
        ? ""
        : bestTradeExactOut
      : token2Value;
  }, [bestTradeExactOut, token2Value, token1Value, currentFn, selectedToken2]);

  useEffect(() => {
    if (!selectedToken2?.symbol && !selectedToken1?.symbol) {
      return;
    }

    dispatch(getEntryPrice(selectedToken1));
    dispatch(getEntryPrice(selectedToken2));
  }, [selectedToken2, selectedToken1]);

  useEffect(() => {
    if (tokens?.length === 0) {
      return;
    }

    setToken1(tokens?.[0]);
    setToken2(tokens?.[1]);
    dispatch({ type: SELECT_TOKEN0, payload: tokens?.[0] });
    dispatch({ type: SELECT_TOKEN1, payload: tokens?.[1] });
  }, [tokens]);

  const {
    isAccountCreated,
    sponsorshipStatus,
    loadingTradeState,
    createSponsorWallet,
    setSponsorshipStatus,
  } = useTradingAccount();

  const token1Contract = useTokenContract(selectedToken1?.address);

  const { createOrder } = useOrderCallback();

  const spender = useMemo(() => {
    if (assetType === ASSET_TYPE.CRYPTO) {
      return PURPED_ADDRESSES.CryptoPerped;
    } else if (assetType === ASSET_TYPE.CRYPTO_INDEX) {
      return PURPED_ADDRESSES.CryptoTradingRRP;
    } else if (assetType === ASSET_TYPE.STOCK) {
      return PURPED_ADDRESSES.StockTradingRRP;
    } else {
      return PURPED_ADDRESSES.TradingRRP;
    }
  }, [assetType]);
  const {
    allowance: tradingRppAllowance,
    confirmAllowance,
    resetTrxState,
  } = useTokenAllowance(selectedToken1, spender);
  const transactionStatus = useSelector((state) => state?.transaction);

  const balance = useCurrencyBalance(account, selectedToken1);

  const currentButton = useMemo(() => {
    if (loadingTradeState) {
      return "Please wait...";
    }

    if (priceLoading) {
      return "Updating price...";
    }

    if (!parsedToken1Value || !parsedToken2Value) {
      return "Enter amounts";
    }

    if (
      new BigNumber(fromWei(balance, selectedToken1?.decimals)).lt(
        parsedToken1Value
      )
    ) {
      return "Insufficient funds in wallet";
    }

    if (!tradingRppAllowance) {
      return "Approve " + selectedToken1?.symbol;
    }

    if (!sponsorshipStatus) {
      return "Sponser your wallet";
    }

    if (!isAccountCreated) {
      return "Create trading account";
    }

    return actionTab === 0
      ? "Open Long"
      : actionTab === 1
      ? "Open Short"
      : "Swap";
  }, [
    tradingRppAllowance,
    selectedToken1,
    isAccountCreated,
    loadingTradeState,
    parsedToken1Value,
    parsedToken2Value,
    sponsorshipStatus,
    actionTab,
    balance,
    priceLoading,
  ]);

  const actionDisabled = useMemo(() => {
    if (loadingTradeState) {
      return true;
    }

    if (priceLoading) {
      return true;
    }

    if (!parsedToken1Value || !parsedToken2Value) {
      return true;
    }

    if (
      new BigNumber(fromWei(balance, selectedToken1?.decimals)).lt(
        parsedToken1Value
      )
    ) {
      return true;
    }

    return false;
  }, [
    loadingTradeState,
    parsedToken1Value,
    parsedToken2Value,
    balance,
    selectedToken1,
  ]);

  const productInfo = useSelector((state) => state?.trade?.tradingInfo);

  const fee = useMemo(() => {
    // fee = ((fee value from getProduct) / (10**6)) x (AMOUNT_OF_MATIC x $MATIC)
    if (
      !tokenPrices?.[selectedToken2?.symbol] ||
      !parsedToken2Value ||
      !productInfo?.fee
    ) {
      return;
    }
    const price = tokenPrices?.[selectedToken2?.symbol];
    let fee = new BigNumber(productInfo?.fee);
    fee = fee?.div(10000000);
    fee = fee?.multipliedBy(parsedToken2Value)?.multipliedBy(price)?.toString();
    return fee;
  }, [tokenPrices, productInfo, selectedToken2, parsedToken2Value]);

  const selectedLeverage = useSelector((state) => state?.trade?.leverage);

  const currentAction = useCallback(async () => {
    // console.log("selected token", { selectedToken2 });

    if (!tradingRppAllowance) {
      // console.log("selected token 1", selectedToken1);
      confirmAllowance(
        toWei(ALLOWANCE_AMOUNT, selectedToken1?.decimals),
        token1Contract
      );

      return;
    }

    if (!sponsorshipStatus) {
      await setSponsorshipStatus();
      return;
    }

    if (!isAccountCreated) {
      await createSponsorWallet();
      return;
    }

    const leverage = selectedLeverage; //"1"; // 10x
    const productId = selectedToken2?.productId;
    const tokenAmountInWei = toWei(parsedToken1Value, selectedToken1?.decimals);
    await createOrder(
      productId,
      tokenAmountInWei,
      selectedToken1?.address,
      leverage,
      isLong,
      fee,
      selectedToken2.type
    );
  }, [
    tradingRppAllowance,
    selectedToken1,
    token1Contract,
    isAccountCreated,
    parsedToken1Value,
    isLong,
    sponsorshipStatus,
    selectedToken2,
    fee,
    selectedLeverage,
    createOrder,
    setSponsorshipStatus,
    createSponsorWallet,
    confirmAllowance,
  ]);

  const handleModalClose = useCallback(() => {
    resetTrxState();
  }, [resetTrxState, transactionStatus]);

  return (
    <Grid container spacing={2}>
      <Grid item md={8}>
        <Grid container className={classes.pairContainer}>
          {/* <Grid item md={5} xs={6}> */}
          <div className={classes.pairBox}>
            <img
              src={selectedToken2.logoURI}
              height="25px"
              alt="logo"
              style={{ borderRadius: "50%" }}
            />
            <span style={{ marginLeft: 5, marginRight: 10 }}>
              {" "}
              {selectedToken2?.name}
            </span>

            {/* USD */}
            {/* <ArrowDropDown /> */}
          </div>
          {/* </Grid> */}
          {/* <Grid item md={2} xs={3} display={"flex"} alignItems="center">
            {" "}
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              alignItems="space-between"
            >
              <h6 className={classes.descText} style={{ fontSize: 12 }}>
                {formatCurrency(2247.99, true)} USD
              </h6>
              <h6 className={classes.titleText} style={{ fontSize: 12 }}>
                Dec 20, 2022
              </h6>
            </Box>
          </Grid>
          <Grid item md={2} xs={3} display={"flex"} alignItems="center">
            {" "}
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              alignItems="space-between"
            >
              <h6
                className={classes.descText}
                style={{ color: "red", fontSize: 12 }}
              >
                - {formatCurrency("67.50")} %
              </h6>

              <h6 className={classes.titleText} style={{ fontSize: 12 }}>
                1 YR RETURN
              </h6>
            </Box>
          </Grid> */}
        </Grid>
        <div className={classes.chartCard}>
          {assetType === ASSET_TYPE.CRYPTO ? <LiveChart /> : <Chart />}
        </div>

        <div className="mt-3">
          <Orders token2={selectedToken2} />
        </div>

        {/* <div className="mt-3">
          <h6 className={classes.titleTextBig}>Useful Links</h6>

          <h6 className={classes.linkText}>Trading Guide</h6>
          <h6 className={classes.linkText}>Leaderboard</h6>
          <h6 className={classes.linkText}>Speed up page loading</h6>
        </div> */}
      </Grid>
      <Grid item md={4}>
        <div elevation={20} className={classes.card}>
          <div className={classes.buttonsContainer}>
            <div
              className={
                actionTab === 0 ? classes.buttonActive : classes.button
              }
              onClick={() => setActionTab(0)}
            >
              Long
            </div>
            <div
              className={
                actionTab === 1 ? classes.buttonActive : classes.button
              }
              onClick={() => setActionTab(1)}
            >
              Short
            </div>
            {/* <div
              className={
                actionTab === 2 ? classes.buttonActive : classes.button
              }
              onClick={() => setActionTab(2)}
            >
              Swap
            </div> */}
          </div>
          <div className={classes.typeContainer}>
            <div
              className={tradeTypeTab === 0 ? classes.typeActive : classes.type}
              onClick={() => setTradeTypeTab(0)}
            >
              Market
            </div>
            {/* <div
              className={tradeTypeTab === 1 ? classes.typeActive : classes.type}
              onClick={() => setTradeTypeTab(1)}
            >
              Limit
            </div>
            <div
              className={tradeTypeTab === 2 ? classes.typeActive : classes.type}
              onClick={() => setTradeTypeTab(2)}
            >
              Trigger
            </div> */}
          </div>
          <div className={classes.inputContainer}>
            <TradeInputGroup
              inputType="Pay"
              onInputChange={onToken1InputChange}
              onTokenChange={onToken1Select}
              currentToken={selectedToken1}
              disableToken={selectedToken2}
              inputValue={parsedToken1Value}
              priceUSD={"0.0"}
              currenryBalance={"0.0"}
              textPrimary={`Pay: $${formatCurrency(
                parsedToken1Value * tokenPrices?.[selectedToken1?.symbol]
              )}`}
              textSecondary={""}
            />
            <div className={classes.iconWrapper}>
              <IconButton className={classes.iconButton}>
                {" "}
                {/* <SwapVerticalCircleSharp
                  fontSize="large"
                  className={classes.swapIcon}
                  onClick={handleSwapInputs}
                /> */}
              </IconButton>
            </div>
            <TradeInputGroup
              inputType="Long"
              onInputChange={onToken2InputChange}
              onTokenChange={onToken2Select}
              currentToken={selectedToken2}
              disableToken={selectedToken1}
              inputValue={parsedToken2Value}
              priceUSD={"0.0"}
              currenryBalance={"0.0"}
              textPrimary={`Long: $${formatCurrency(
                parsedToken2Value * tokenPrices?.[selectedToken2?.symbol]
              )}`}
              textSecondary={"Leverage: 1x"}
            />
          </div>
          <div className={classes.smallHeading}>Leverage Slider</div>

          <div className={classes.cardContents}>
            <Box width={"100%"}>
              <TradeInfo
                isLong={isLong}
                token2={selectedToken2}
                token1Amount={parsedToken1Value}
                token2Amount={parsedToken2Value}
              />
            </Box>

            {active && (
              <Button
                disabled={actionDisabled}
                onClick={currentAction}
                style={{
                  marginTop: 10,
                  backgroundColor: "#1C1F23",
                  color: "#4369B0",
                  width: "100%",
                  textTransform: "none",
                  fontSize: 14,
                  fontWeight: 400,
                  borderRadius: 10,
                  willChange: "transform",
                  transition: "transform 450ms ease 0s",
                  transform: "perspective(1px) translateZ(0px)",
                  padding: "10px 15px 10px 15px",
                  cursor: "pointer",
                }}
              >
                {currentButton}
              </Button>
            )}
            {!active && (
              <Button
                onClick={handleConnectWallet}
                style={{
                  marginTop: 10,
                  backgroundColor: "#1C1F23",
                  color: "#4369B0",
                  width: "100%",
                  textTransform: "none",
                  fontSize: 14,
                  fontWeight: 400,
                  borderRadius: 10,
                  willChange: "transform",
                  transition: "transform 450ms ease 0s",
                  transform: "perspective(1px) translateZ(0px)",
                  padding: "10px 15px 10px 15px",
                  cursor: "pointer",
                }}
              >
                Connect Wallet
              </Button>
            )}
          </div>
          {/* <div>
            <div>
              <div className={classes.smallHeading}>Long APPL</div>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent="space-between"
                mt={1}
              >
                <h6 className={classes.titleText}>Entry Price</h6>
                <h6 className={classes.descText}>$142.40</h6>
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent="space-between"
              >
                <h6 className={classes.titleText}>Exit Price</h6>
                <h6 className={classes.descText}>$142.40</h6>
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent="space-between"
              >
                <h6 className={classes.titleText}>Borrow Fees</h6>
                <h6 className={classes.descText}>$142.40</h6>
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent="space-between"
              >
                <h6 className={classes.titleText}>Market Cap</h6>
                <h6 className={classes.descText}>$142.40</h6>
              </Box>
            </div>
          </div> */}
          <PopupLayout popupActive={transactionStatus?.state > 0}>
            <TxPopup
              type={transactionStatus.type}
              txCase={transactionStatus?.state}
              hash={transactionStatus?.hash}
              resetPopup={handleModalClose}
            />
          </PopupLayout>
        </div>
      </Grid>
    </Grid>
  );
}
