import BigNumber from "bignumber.js";
import moment from "moment";
import { SupportedChainId } from "../constants/chains";
import Numeral from "numeral";
import axios from "axios";
import Web3 from "web3";
import { ASSET_TYPE } from "../constants";

export const fromWei = (tokens, decimals = 18) => {
  try {
    if (!tokens) {
      return new BigNumber(0).toString();
    }

    return new BigNumber(tokens)
      .div(new BigNumber(10).exponentiatedBy(decimals))
      .toString();
  } catch (error) {
    console.log("exeption in fromWei ", error);
    return null;
  }
};

export const toWei = (tokens, decimals = 18) => {
  try {
    if (!tokens) {
      return new BigNumber(0).toString();
    }
    return new BigNumber(tokens)
      .multipliedBy(new BigNumber(10).exponentiatedBy(decimals))
      .toFixed(0)
      .toString();
  } catch (error) {
    console.log("exeption in toWei , ", error);
    return null;
  }
};

export const formatLargeNumber = (value, precision = 2) => {
  const _value = !value ? "0" : value;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: precision,
  });

  const formattedValue = convertToInternationalCurrencySystem(
    _value,
    formatter
  );

  return formattedValue;
};

export const formatCurrency = (
  value,
  usd = false,
  fractionDigits = 1,
  currencyFormat = false
) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
  });

  //for currency format with $symbol
  if (usd) {
    return formatter.format(value ? value : 0)?.slice(1);
  }

  if (typeof window.web3 === "undefined") {
    return formatter.format(value ? value : 0).slice(1);
  }
  const netId = window.ethereum.networkVersion;
  if (["97", "56"].includes(netId) && !currencyFormat) {
    // for bsc network only
    return convertToInternationalCurrencySystem(value ? value : 0, formatter);
  }
  return formatter.format(value ? value : 0).slice(1);
};

function convertToInternationalCurrencySystem(labelValue, formatter) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? formatter
        .format((Math.abs(Number(labelValue)) / 1.0e9).toFixed(2))
        .slice(1) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? formatter
        .format((Math.abs(Number(labelValue)) / 1.0e6).toFixed(2))
        .slice(1) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? formatter
        .format((Math.abs(Number(labelValue)) / 1.0e3).toFixed(2))
        .slice(1) + "K"
    : formatter.format(Math.abs(Number(labelValue))).slice(1);
}

export const resetCurrencyFormatting = (value) => {
  return value.split(",").join("");
};

export const isNumber = (value) => {
  return !isNaN(parseInt(value));
};

export const isMetaMaskInstalled = () => {
  return typeof window.web3 !== "undefined";
};

export const formattedAddress = (address) => {
  const _address = address?.toString();
  if (!address) {
    return "";
  }

  const _formatted = _address?.slice(0, 4) + "..." + _address?.slice(-4);
  return _formatted;
};
// //input  { chainId, chainName, currency: {name, symbol, decimals }, rpcUrls, blockExplorer }
export const setupNetwork = async (networkObject) => {
  const provider = window.ethereum;
  if (provider) {
    // const _chainId = parseInt(networkObject.chainId, 10)
    try {
      if (
        networkObject.chainId ===
          `0x${SupportedChainId.MAINNET.toString(16)}` ||
        networkObject.chainId === `0x${SupportedChainId.GOERLI.toString(16)}`
      ) {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: networkObject.chainId }],
        });
      }
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [networkObject],
      });
      return true;
    } catch (error) {
      console.error("Failed to setup the network in Metamask:", error);
      return false;
    }
  } else {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};

export const formatTime = (dateString, requirement = null) => {
  if (!dateString) {
    return "";
  }

  if (!requirement) {
    return moment(moment.utc(dateString).toDate())
      .local()
      .format("hh:mm A DD-MM-YYYY");
  }

  if (requirement === "date-only") {
    return moment(moment.utc(dateString).toDate()).local().format("DD-MM-YYYY");
  }
};

export const compareStrings = (str1, str2) => {
  if (!str1 || !str2) {
    return false;
  }
  const str1WithoutSpacing = str1?.replace(/^\s+|\s+$/gm, "");
  const str2WithoutSpacing = str1?.replace(/^\s+|\s+$/gm, "");
  return str1WithoutSpacing === str2WithoutSpacing;
};

export const recoverKeys = (key) => {
  if (!key) {
    return null;
  }
  return key?.split("").reverse().join("");
};

export const getTrxEtherscanUrl = (trxHash, chainId) => {
  if (chainId === 5) {
    return `https://goerli.etherscan.io/tx/${trxHash}`;
  }

  if (chainId === 137) {
    return `https://polygonscan.com/tx/${trxHash}`;
  }

  if (chainId === 1) {
    return `https://etherscan.com/tx/${trxHash}`;
  }

  return `https://testnet.bscscan.com/tx/${trxHash}`;
};

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

export const toK = (num) => {
  return Numeral(num).format("0.[00]a");
};

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const formatDollarAmount = (num, digits) => {
  const formatter = new Intl.NumberFormat([], {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return formatter.format(num);
};

export const formattedNum = (number, usd = false, acceptNegatives = false) => {
  if (isNaN(number) || number === "" || number === undefined) {
    return usd ? "$0" : 0;
  }
  let num = parseFloat(number);

  if (num > 500000000) {
    return (usd ? "$" : "") + toK(num.toFixed(0), true);
  }

  if (num === 0) {
    if (usd) {
      return "$0";
    }
    return 0;
  }

  if (num < 0.0001 && num > 0) {
    return usd ? "< $0.0001" : "< 0.0001";
  }

  if (num > 1000) {
    return usd
      ? formatDollarAmount(num, 0)
      : Number(parseFloat(num).toFixed(0)).toLocaleString();
  }

  if (usd) {
    if (num < 0.1) {
      return formatDollarAmount(num, 4);
    } else {
      return formatDollarAmount(num, 2);
    }
  }

  return Number(parseFloat(num).toFixed(4)).toString();
};

const tokenPrices = {
  PRPD: 0.023,
};
const tokenIds = {
  MATIC: "matic-network",
  ETH: "ethereum",
  PRPD: "purped",
  USDT: "tether",
  fUSDC: "usd-coin",
};
export const getTokenPriceFromCoinGecko = async (token) => {
  try {
    const token_id = tokenIds?.[token?.symbol];

    if (token_id === tokenIds.PRPD) {
      const price = tokenPrices?.[token?.symbol];
      return price;
    }

    const priceRes = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${token_id}&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false`
    );

    const priceData = priceRes.data;
    const tokenPrice = priceData?.[token_id] ? priceData[token_id].usd : "0";
    console.log("price response ", priceRes);

    return tokenPrice;
  } catch (error) {
    console.log("fetchTokenPrice ", { error });
    return 0;
  }
};

export const getBtcPrice = async () => {
  try {
    const result = await axios.get(
      "https://data.binance.com/api/v3/ticker/24hr?symbol=BTCUSDT"
    );

    return result.data;
  } catch (error) {
    return {};
  }
};

export const globalHeaders = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

export async function fetchAssetPrice(productId) {
  try {
    var data = JSON.stringify({
      parameters: {
        Filter: `symbol IN (\"${productId}\")`,
      },
    });

    var config = {
      method: "post",
      url: "https://41pi1rpf8c.execute-api.us-east-1.amazonaws.com/v1/0xfd699056bd43b5d6f73dd8a1795b04458f50efe683c0b7788fbfc5c2b8e27ecd",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "65447e9f-349b-45bf-9cfc-fca252d10575",
      },
      data: data,
    };

    const result = await axios.post(config.url, data, {
      headers: config.headers,
    });

    console.log("asset price result ", result.data);
    return fromWei(result?.data?.values?.[0], 8);
  } catch (error) {
    console.log("result error  ", error);

    return null;
  }
}

export async function fetchCryptoAssetPrice(productId) {
  try {
    var data = JSON.stringify({
      parameters: {
        indexKey: `${productId}`,
      },
    });

    var config = {
      method: "post",
      url: "https://5j2agr56ee.execute-api.us-east-1.amazonaws.com/v1/0x0eb9e5c50a58e5dcbeed5064f21b7cf1f7644edb06fa9a967b853152304712fb",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "eyfsnavRC91oJAjRvLi0tEuFzqYnWRPB",
      },
      data: data,
    };

    console.log("crypto asset price fetching ", { data });
    const result = await axios.post(config.url, data, {
      headers: config.headers,
    });

    console.log("crypto asset price result ", result.data);
    return fromWei(result?.data?.values?.[0], 8);
  } catch (error) {
    console.log("crypto asset price  error  ", { error, productId });

    return null;
  }
}

export async function fetchStockPrice(productId) {
  try {
    var data = JSON.stringify({
      parameters: {
        events: "Trade",
        symbols: productId,
        _times: "100",
        _path: `Trade.${productId}.price`,
        _type: "uint256",
      },
    });

    var config = {
      method: "post",
      url: "https://rqb4hisldd.execute-api.us-east-1.amazonaws.com/v1/0x1c16815faf43a039e23cf3f2486470d455a1b1c59df0cec57015e36421d09696",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "87cc25f1-0d55-4cc5-b8d9-bfa73cdf44b1",
      },
      data: data,
    };

    const result = await axios(config);

    console.log("stick  price result ", result.data);
    return fromWei(result?.data?.values?.[0], 2);
  } catch (error) {
    console.log("stock price result error  ", { error });

    return null;
  }
}

export const getProductIdHex = (productId, assetType) => {
  const web3 = new Web3(
    new Web3.providers.HttpProvider("https://rpc.ankr.com/polygon")
  );

  let productIdHex;
  if (assetType === ASSET_TYPE.STOCK) {
    productIdHex =
      web3.utils.toHex(productId) +
      "00000000000000000000000000000000000000000000000000000000";
  } else if (assetType === ASSET_TYPE.CRYPTO_INDEX) {
    productIdHex = web3.utils.toHex(productId) + "00000000000000";
  } else {
    productIdHex =
      web3.utils.toHex(productId) +
      "00000000000000000000000000000000000000000000000000";
  }

  return productIdHex;
};

export function formatDateString(dateString) {
  try {
    if (!dateString) {
      return "";
    }
    let newString = dateString.split("/")?.reverse()?.join("-");

    return newString;
  } catch (error) {
    console.log("chart test error ", error);
  }
}

export const dateToUnixTime = (dateString) => {
  if (!dateString) {
    return 0;
  }
  return formatDateString(dateString);
};

export const getPnl = (isLong, price, positionPrice, size) => {
  if (!price || !positionPrice || !size) {
    return "0.00";
  }
  let pnl, isPnlNegative;
  if (isLong) {
    if (new BigNumber(price).gte(positionPrice)) {
      pnl = new BigNumber(size)
        .multipliedBy(new BigNumber(price).minus(positionPrice))
        .div(positionPrice);
    } else {
      pnl = new BigNumber(size)
        .multipliedBy(new BigNumber(positionPrice).minus(price))
        .div(positionPrice);
      isPnlNegative = true;
    }
  } else {
    if (new BigNumber(price).gt(positionPrice)) {
      pnl = new BigNumber(size)
        .multipliedBy(new BigNumber(price).minus(positionPrice))
        .div(positionPrice);
      isPnlNegative = true;
    } else {
      pnl = new BigNumber(size)
        .multipliedBy(new BigNumber(positionPrice).minus(price))
        .div(positionPrice);
    }
  }

  if (isPnlNegative) {
    return pnl.multipliedBy(-1)?.toFixed(3);
  }
  return pnl.toFixed(3);
};
