import { RESET_TRANSACTION, START_TRANSACTION, TRANSACTION_ERROR, UPDATE_TRANSACTION } from "../actions/types";

const initalState = {
    hash: "",
    status: null,
    state: 0,
    type: null,
    error: null,
};

export default function (state = initalState, action) {
    // todo design and write action types and state updates
    switch (action.type) {
        case START_TRANSACTION:
            return {
                ...state,
                ...action.payload,
                error: null
            };
        case UPDATE_TRANSACTION:
            return {
                ...state,
                ...action.payload,
                error: null
            }
        case RESET_TRANSACTION:
            return {
                ...state,
                hash: "",
                status: null,
                state: 0,
                type: null,
                error: null
            }
        case TRANSACTION_ERROR:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }
}
