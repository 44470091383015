import { CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BigNumber from "bignumber.js";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { hashToName } from "../../constants";
import {
  formatCurrency,
  formattedNum,
  fromWei,
  getPnl,
  getTrxEtherscanUrl,
} from "../../utils/helper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { PendingActionsSharp } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  tr: {
    width: "100%",
    backgroundColor: "transparent",
  },
  trHighlight: {
    width: "100%",
    backgroundColor: "transparent",
  },
  tableData: {
    padding: "12px 0px",
    fontSize: 14,
    fontWeight: 500,
    color: "#1943DB",
  },
  otherText: {
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontWeight: 400,
      fontSize: 12,
    },
  },
}));

const OrderTableRow = ({ data, index, handleClosePosition, currentTab }) => {
  const classes = useStyles();

  const productInfo = useSelector((state) => state?.trade?.tradingInfo);

  const tokenPrices = useSelector((state) => state?.trade?.tokenPrices);
  const token1 = useSelector((state) => state?.trade?.token1);
  const { chainId } = useActiveWeb3React();

  console.log("position data ", data);
  const price = useMemo(() => {
    if (data.completed) {
      return fromWei(data?.price, 8);
    }

    if (!token1?.symbol) {
      return "0";
    }

    if (!tokenPrices) {
      return "0";
    }

    return tokenPrices?.[token1?.symbol];
  }, [data, tokenPrices, token1]);

  const liquidationPrice = useMemo(() => {
    if (!price || !productInfo?.liquidationThreshold) {
      return;
    }

    const _price = new BigNumber(price);
    let liqThresold = new BigNumber(productInfo?.liquidationThreshold).div(
      1000
    );

    if (!data?.isLong) {
      return _price.multipliedBy(liqThresold)?.toString();
    }
    return _price.div(liqThresold)?.toString();
  }, [price, productInfo, data]);

  const pnl = useMemo(() => {
    // console.log("pnl test ", {
    //   isLong: data?.isLong,
    //   tokenPrice: tokenPrices?.[token1?.symbol],
    //   positionPrice: fromWei(data?.price, 8),
    //   size: fromWei(data?.size, 8),
    //   data,
    // });
    if (!data?.completed && currentTab === 0) {
      return "...";
    }

    const _pnl = getPnl(
      data?.isLong,
      tokenPrices?.[token1?.symbol],
      fromWei(data?.price, 8),
      fromWei(data?.size, 8)
    );
    // console.log("pnl test pnl ", _pnl);
    return "$" + _pnl;
  }, [tokenPrices, token1, data, currentTab]);
  const transactionStatus = useSelector((state) => state?.transaction);
  const isClosing = useMemo(() => {
    const closingOrderId = localStorage.getItem("closing");
    if (!closingOrderId) {
      return false;
    }

    if (closingOrderId === data?.id) {
      return true;
    }
    return false;
  }, [data, transactionStatus]);

  return (
    <tbody>
      <tr className={index % 1 === 0 ? classes.trHighlight : classes.tr}>
        <td className={classes.tableData}>
          <Typography
            fontFamily="Source Code Pro"
            textAlign="left"
            variant="body2"
            color={"#ffffff"}
            fontSize={14}
            style={{ fontWeight: 500 }}
            className={classes.otherText}
          >
            <a
              style={{ textDecoration: "none", color: "#ffffff" }}
              href={getTrxEtherscanUrl(data?.hash, chainId)}
              target="_blank"
            >
              {currentTab === 0 && (
                <>
                  {data?.completed ? (
                    <div className="flex">
                      <span> {data?.isLong ? "Long" : "Short"}</span>
                    </div>
                  ) : (
                    <div className="d-flex justify-contotherTextent-center">
                      <PendingActionsSharp
                        size={20}
                        thickness={10}
                        style={{ marginRight: 4 }}
                      />
                      <span> {data?.isLong ? "Long" : "Short"}</span>
                    </div>
                  )}
                </>
              )}
              {currentTab === 1 && (
                <span> {data?.isLong ? "Long" : "Short"}</span>
              )}
              {/* <OpenInNewIcon fontSize="small" style={{ marginRight: 4 }} /> */}
            </a>
          </Typography>
        </td>
        <td className={classes.tableData}>
          <Typography
            textAlign="left"
            variant="body2"
            color={"#ffffff"}
            fontSize={14}
            style={{ fontWeight: 500 }}
            fontFamily="Source Code Pro"
            className={classes.otherText}
          >
            {token1?.symbol}
          </Typography>
        </td>
        <td className={classes.tableData}>
          <Typography
            textAlign="left"
            variant="body2"
            color={"#ffffff"}
            fontSize={14}
            style={{ fontWeight: 500 }}
            fontFamily="Source Code Pro"
            className={classes.otherText}
          >
            {data?.margin > 0 ? formattedNum(data?.size / data?.margin) : 0}x
          </Typography>
        </td>
        <td className={classes.tableData}>
          <Typography
            textAlign="left"
            variant="body2"
            color={"#ffffff"}
            fontSize={14}
            style={{ fontWeight: 500 }}
            fontFamily="Source Code Pro"
            className={classes.otherText}
          >
            ${formatCurrency(price)}
          </Typography>
        </td>
        <td className={classes.tableData}>
          <Typography
            textAlign="left"
            variant="body2"
            color={"#ffffff"}
            fontSize={14}
            style={{ fontWeight: 500 }}
            className={classes.otherText}
          >
            ${formatCurrency(liquidationPrice)}
          </Typography>
        </td>
        <td className={classes.tableData}>
          <Typography
            textAlign="left"
            variant="body2"
            color={"#ffffff"}
            fontSize={14}
            fontFamily="Source Code Pro"
            style={{ fontWeight: 500 }}
            className={classes.otherText}
          >
            ${formatCurrency(fromWei(data?.margin, 8))}
          </Typography>
        </td>

        <td className={classes.tableData}>
          <Typography
            textAlign="left"
            variant="body2"
            color={"#ffffff"}
            fontSize={14}
            style={{ fontWeight: 500 }}
            fontFamily="Source Code Pro"
            className={classes.otherText}
          >
            {(data?.completed || currentTab === 1) &&
              "$" + formatCurrency(fromWei(data?.fee, 8))}
            {!data?.completed && currentTab === 0 && "..."}
          </Typography>
        </td>
        <td className={classes.tableData}>
          <Typography
            textAlign="left"
            variant="body2"
            color={"#ffffff"}
            fontSize={14}
            style={{ fontWeight: 500 }}
            fontFamily="Source Code Pro"
            className={classes.otherText}
          >
            {pnl}
          </Typography>
        </td>
        {currentTab === 0 && data.completed && (
          <td className={classes.tableData}>
            <Typography
              textAlign="left"
              variant="body2"
              color={"#AE58BD"}
              fontFamily="Source Code Pro"
              fontSize={14}
              style={{ fontWeight: 500 }}
              className={classes.otherText}
            >
              {isClosing ? (
                <div>
                  <CircularProgress size={20} thickness={10} />
                </div>
              ) : (
                <div
                  onClick={() => handleClosePosition(data)}
                  style={{ cursor: "pointer" }}
                >
                  {/* <CancelIcon
                    fontSize="small"
                    style={{ marginRight: 4, cursor: "pointer" }}
                  /> */}
                  Close
                </div>
              )}
            </Typography>
          </td>
        )}
      </tr>
    </tbody>
  );
};

export default OrderTableRow;
