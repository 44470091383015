import {
  fetchAssetPrice,
  fetchCryptoAssetPrice,
  fetchStockPrice,
  getBtcPrice,
  getTokenPriceFromCoinGecko,
} from "../utils/helper";
import {
  GET_ENTRY_PRICE,
  GET_EXACT_IN,
  GET_EXACT_OUT,
  GET_FULFILLED_ORDERS,
  GET_OPEN_ORDERS,
  SET_LOADING,
  UPDATE_OPEN_ORDER,
} from "./types";
import BigNumber from "bignumber.js";
import { ASSET_TYPE } from "../constants";

export const fetchOpenTrades =
  (account, productId, block) => async (dispatch) => {
    if (!account) {
      return;
    }

    const [orders, completed] = await Promise.all([
      // getOpenOrders(account?.toLowerCase(), productId, block),
      // getFullFilledOrders(account?.toLowerCase(), productId, 10),
    ]);

    if (!orders || orders?.length === 0) {
      dispatch({ type: GET_OPEN_ORDERS, payload: [] });
      return;
    }

    let pendingOrders = localStorage.getItem(`pending_orders${productId}`);
    pendingOrders = pendingOrders ? JSON.parse(pendingOrders) : null;

    if (pendingOrders && pendingOrders?.[0]?.completed === false) {
      if (pendingOrders?.[0]?.hash === orders?.[0]?.hash) {
        dispatch({
          type: UPDATE_OPEN_ORDER,
          payload: { ...pendingOrders?.[0], completed: orders?.[0]?.completed },
        });
      }
      return;
    }

    if (!completed) {
      // console.log("cached pending order", { pendingOrders, orders });

      if (!pendingOrders) {
        dispatch({ type: GET_OPEN_ORDERS, payload: orders });
        return;
      }

      if (pendingOrders?.[0]?.hash === orders?.[0].hash) {
        pendingOrders = [
          {
            ...pendingOrders?.[0],
            completed: orders?.[0]?.completed,
            id: orders?.[0]?.id,
          },
        ];
      }

      dispatch({ type: GET_OPEN_ORDERS, payload: pendingOrders });
    } else {
      let pendingOrder = orders?.[0];
      // console.log("order filter ", { completed, pendingOrder });

      completed?.forEach((element) => {
        if (element?.requestId === pendingOrder?.requestId) {
          pendingOrder = {
            ...pendingOrder,
            completed: element?.completed,
            fee: element?.fee,
            margin: element?.margin,
            size: element?.size,
            isLong: element?.isLong,
          };
        }
      });

      dispatch({ type: GET_OPEN_ORDERS, payload: [pendingOrder] });
    }
  };

export const fetchCompletedTrades =
  (account, productId, page = 1) =>
  async (dispatch) => {
    if (!account) {
      return;
    }

    console.log("order filter fetching open");
    // const orders = await getFullFilledOrders(
    //   account?.toLowerCase(),
    //   productId,
    //   page
    // );

    dispatch({ type: GET_FULFILLED_ORDERS, payload: [] });
  };

export const getEntryPrice = (token) => async (dispatch) => {
  if (!token?.symbol) {
    return;
  }

  console.log("trade test fetching entry price for ", token);
  let price;
  dispatch({ type: SET_LOADING, payload: true });
  if (token?.type === ASSET_TYPE.STOCK) {
    price = await fetchStockPrice(token?.productId);
  } else if (token?.type === ASSET_TYPE.PLATTS) {
    price = await fetchAssetPrice(token?.productId);
  } else if (token?.type === ASSET_TYPE.CRYPTO_INDEX) {
    price = await fetchCryptoAssetPrice(token?.productId);
  } else {
    price = await getTokenPriceFromCoinGecko(token);
    console.log("trade test token price fetched ", price);
  }
  const btcPrice = await getBtcPrice();
  dispatch({ type: SET_LOADING, payload: false });

  const priceObj = {};
  priceObj[token?.symbol] = price;
  dispatch({ type: GET_ENTRY_PRICE, payload: { priceObj, btcPrice } });
};

export const getExactOut =
  (token1Amount, token1Pirce, token2Price) => async (dispatch) => {
    if (!token1Amount) {
      return;
    }

    console.log("trade test ", { token1Amount, token2Price, token1Pirce });
    const tokenOut = new BigNumber(token1Amount)
      .multipliedBy(token1Pirce)
      .div(token2Price);

    dispatch({ type: GET_EXACT_OUT, payload: tokenOut });
  };

export const getExactIn =
  (token2Amount, token1Pirce, token2Price) => async (dispatch) => {
    if (!token2Amount) {
      return;
    }

    ///todo:
    // calculate token2Amount  given input tokens and prices
    const tokenIn = new BigNumber(token2Amount)
      .multipliedBy(token2Price)
      .div(token1Pirce);

    const token1Amount = "0";

    dispatch({ type: GET_EXACT_IN, payload: tokenIn });
  };
