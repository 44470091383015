import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import useBlockNumber from "../../hooks/useBlockNumber";
import OrderTable from "./OrderTable";
import { useOrders } from "../../hooks/useOrders";
// import { TransactionStatus } from "../../utils/interface";

const useStyles = makeStyles((theme) => ({
  titleText: {
    color: "#4369B0",
    fontSize: 11,
    padding: 0,
    letterSpacing: 1.1,
  },

  ordersBox: {
    fontFamily: "Source Code Pro",
    border: "1px solid #24222D",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "white",
    padding: "20px",
    borderRadius: 10,
    letterSpacing: 1.5,
    minWidth: "20%",
    maxHeight: "fit-content",
  },
  ordersTabs: {
    borderBottom: "1px solid #24222D",
    display: "flex",
    alignItems: "center",
    gap: "20px",
    justifyContent: "flex-start",
  },
  tableTab: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#ffffff",
    padding: 0,
    letterSpacing: 1.1,
    paddingBottom: 10,
    width: "fit-content",
    marginBottom: 10,
    cursor: "pointer",
  },
  tableTabActive: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#5E7ECE",
    padding: 0,
    letterSpacing: 1.1,
    paddingBottom: 10,
    width: "fit-content",
    marginBottom: 10,
    cursor: "pointer",
  },
  chartCard: {
    marginTop: 10,
    padding: 10,
    border: "1px solid #4369B0",
    borderRadius: 10,
    minHeight: 300,
  },
  titleTextBig: {
    color: "#4369B0",
    fontSize: 14,
    padding: 0,
    letterSpacing: 1.1,
    borderBottom: "2px solid #4369B0",
    paddingBottom: 10,
    width: "fit-content",
    marginBottom: 10,
  },
  linkText: {
    color: "#ffffff",
    fontSize: 14,
    padding: 0,
    letterSpacing: 1.1,
  },
  divider: {
    color: "#4369B0",
    border: "2px solid #4369B0",
    width: 100,
  },
}));

export default function Orders({ token2 }) {
  const classes = useStyles();

  const { account } = useActiveWeb3React();
  const [orderTab, setOrderTab] = useState(0);
  const [page, setPage] = useState(1);

  const pendingOrders = useSelector((state) => state?.trade?.pendingTrades);
  const completedOrders = useSelector((state) => state?.trade?.completedTrades);
  const closedOrders = useSelector((state) => state?.trade?.closedOrders);

  const currentTrades = useMemo(() => {
    console.log("pending trades ", pendingOrders);
    if (orderTab === 1) {
      return closedOrders;
    }
    return [...pendingOrders, ...completedOrders];
  }, [orderTab, pendingOrders, completedOrders, closedOrders]);

  const blockNumber = useBlockNumber();
  const transactionStatus = useSelector((state) => state?.transaction);

  const {
    isPendingLoading,
    isCloseLoading,
    isCompletedLoading,
    loadCompletedOrders,
    loadPendingOrder,
    loadClosedOrders,
    error,
  } = useOrders();

  useEffect(() => {
    console.log("update test  fetching", blockNumber);
    loadCompletedOrders();
    loadPendingOrder();
    loadClosedOrders();
  }, [account, token2]);

  // useEffect(() => {
  //   setInterval(() => {
  //     console.log("update test  fetching after 3 sec");
  //     loadCompletedOrders();
  //     loadPendingOrder();
  //   }, [5000]);
  // }, []);

  // transaction watch to

  // useEffect(() => {
  //   console.log("update test ", transactionStatus);
  //   if (transactionStatus?.status === TransactionStatus.COMPLETED) {
  //     setTimeout(() => {
  //       console.log("update test fetching");
  //       loadCompletedOrders();
  //       loadPendingOrder();
  //       loadClosedOrders();
  //     }, 5000);
  //     setTimeout(() => {
  //       console.log("update test fetching");
  //       loadCompletedOrders();
  //       loadPendingOrder();
  //       loadClosedOrders();
  //     }, 3000);
  //   }
  // }, [transactionStatus]);

  return (
    <div className={classes.ordersBox}>
      <div className={classes.ordersTabs}>
        <h6
          className={orderTab === 0 ? classes.tableTabActive : classes.tableTab}
          onClick={() => setOrderTab(0)}
        >
          Open Positions
        </h6>
        {/* <h6 className={orderTab === 1 ? classes.tableTabActive : classes.tableTab} onClick={() => setOrderTab(1)} >Fulfilled</h6> */}
        <h6
          className={orderTab === 1 ? classes.tableTabActive : classes.tableTab}
          onClick={() => setOrderTab(1)}
        >
          Closed Positions
        </h6>
      </div>
      <div>
        <OrderTable
          orders={currentTrades}
          changePage={() => {}}
          page={page}
          totalPages={1}
          currentTab={orderTab}
          loading={isPendingLoading || isCloseLoading || isCompletedLoading}
        />
      </div>
    </div>
  );
}
