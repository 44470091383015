import React, { useMemo } from "react";
import ACRCA00 from "../../utils/data/price/ACRCA00.json";
import BATCH04 from "../../utils/data/price/BATCH04.json";
import BATCP04 from "../../utils/data/price/BATCP04.json";
import CNCAD00 from "../../utils/data/price/CNCAD00.json";
import ETH_INDEX from "../../utils/data/price/ETH-INDEX.json";
import BTC_INDEX from "../../utils/data/price/BTC-INDEX.json";
import mega_cap from "../../utils/data/price/mega_cap.json";
import ex_megacap from "../../utils/data/price/ex_mega_cap.json";
import lc_crypto_index from "../../utils/data/price/crypto_lc_index.json";
import TSLA from "../../utils/data/price/TSLA.json";
import GOOG from "../../utils/data/price/GOOG.json";
import SPY from "../../utils/data/price/SPY.json";
import AMZN from "../../utils/data/price/AMZN.json";
import MSFT from "../../utils/data/price/MSFT.json";
import AAPL from "../../utils/data/price/AAPL.json";
import ETH_USD from "../../utils/data/price/ETH-USD.json";
import { useSelector } from "react-redux";
import AreaChart from "./AreaChart";
import { ASSET_TYPE } from "../../constants";
import { formatDateString } from "../../utils/helper";

const chartData = {
  ACRCA00: ACRCA00,
  BATCH04: BATCH04,
  BATCP04: BATCP04,
  CNCAD00: CNCAD00,
  "ETH(USD)": ETH_INDEX,
  "BTC(USD)": BTC_INDEX,
  "MC-CRYPTO": mega_cap,
  "LC-CRYPTO": ex_megacap,
  "LC-INDEX": lc_crypto_index,
  TSLA: TSLA,
  SPY: SPY,
  GOOG: GOOG,
  AMZN: AMZN,
  MSFT: MSFT,
  AAPL: AAPL,
  "ETH-USD": ETH_USD,
};

export default function Chart() {
  const token1 = useSelector((state) => state?.trade?.token1);

  const currentData = useMemo(() => {
    if (!token1?.productId) {
      return [];
    }
    if (token1?.type === ASSET_TYPE.CRYPTO_INDEX) {
      let data = chartData?.[token1?.symbol];
      data = data?.map((item) => {
        return {
          Mod_Date: formatDateString(item?.Mod_Date),
          Value: item.Value,
        };
      });
      return data;
    }
    const data = chartData?.[token1?.productId];
    return data;
  }, [token1]);

  return (
    <div>
      <AreaChart chartData={currentData} />{" "}
    </div>
  );
}
